import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { Icon } from "../../../../components/shared/Icon/Icon";
import { useMenuContext } from "../../../../lib/context/MenuContext/MenuContext";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useFetchMenu } from "../lib/useFetchMenu";
import { useQuery } from "@apollo/react-hooks";
import * as shopQueries from "../../../../queries/shop";
import { ProductTaxonomyEnum, TaxonomyOperatorEnum } from "../../../../types/generalTypes";
import { Spinner } from "reactstrap";

import "./MenuResponsive.scss";

export const MenuResponsive = () => {
  const ref = useRef(null);
  const menuCtx = useMenuContext();
  const { categories } = useFetchMenu();

  const [openedCategory, setOpenedCategory] = useState("");
  const [openedSubCategory, setOpenedSubCategory] = useState("");

  const { data, loading: finalCategoriesLoading } = useQuery<
    shopQueries.GetShopProductCategoriesResponse,
    shopQueries.GetShopProductCategoriesVariables
  >(shopQueries.getProductCategories, {
    variables: {
      customTaxonomy: [
        {
          taxonomy: ProductTaxonomyEnum.PAGJINIA,
          terms: openedCategory,
          operator: TaxonomyOperatorEnum.AND,
        },
      ],
    },
  });

  const childs = data?.productCategories?.nodes || [];

  useOutsideClickDetection(ref, () => menuCtx.closeSidebar());

  const handleSelectCategory = (slug: string) => {
    if (openedCategory === slug) {
      setOpenedCategory("");
      return;
    }

    setOpenedCategory(slug);
  };

  const handleSelectSubCategory = (slug: string) => {
    if (openedSubCategory === slug) {
      setOpenedSubCategory("");
      return;
    }

    setOpenedSubCategory(slug);
  };

  return (
    <div ref={ref} className={cn("MenuResponsive", menuCtx.isOpened && "MenuResponsive__opened")}>
      <span role='button' className='MenuResponsive__close' onClick={menuCtx.closeSidebar}>
        <Icon icon='x' />
      </span>

      <div className='MenuResponsive__list'>
        <div data-root='true' className='MenuResponsive__list-item'>
          <div className='MenuResponsive__list-item-toggle'>
            <NavLink to='/koleksioni-ri'>Arritjet e reja</NavLink>
          </div>
        </div>
        <div className='MenuResponsive__list-item MenuResponsive__list-item--has-children'>
          <>
            {categories?.map((category: any) => {
              const selected = openedCategory === category.slug;

              const hasChildrens = category.products?.nodes.length > 0;

              return (
                <div
                  key={category.id}
                  data-root='true'
                  className={cn(
                    "MenuResponsive__list-item",
                    category?.children?.nodes && "MenuResponsive__list-item--has-children",
                  )}
                >
                  <div data-opened={selected} className='MenuResponsive__list-item-toggle'>
                    <NavLink to={`veshje-${category.slug}`}>{category.name}</NavLink>
                    <span role='button' onClick={() => handleSelectCategory(category.slug)}>
                      {hasChildrens && <Icon icon='chevron-down' className='chevron' />}
                    </span>
                  </div>
                  {selected &&
                    (finalCategoriesLoading ? (
                      <Spinner style={{ width: "1rem", height: "1rem" }} color='black' />
                    ) : (
                      <ul className='MenuResponsive__list-item-submenu'>
                        {childs?.map((c: any) => {
                          const hasCChildrens = c.children?.nodes?.length > 0;
                          const cSelected = openedSubCategory === c.slug;

                          return (
                            <div className='mt-3'>
                              <li
                                data-opened={cSelected}
                                className='MenuResponsive__list-item-submenu-submenu-toggle'
                                key={c.id}
                              >
                                <NavLink
                                  className='MenuResponsive__list-item-submenu-submenu'
                                  to={`/veshje-${category.slug}/${c?.slug}`}
                                >
                                  {c.name}
                                </NavLink>
                                {hasCChildrens && (
                                  <span
                                    role='button'
                                    onClick={() => handleSelectSubCategory(c.slug)}
                                  >
                                    {hasChildrens && (
                                      <Icon icon='chevron-down' className='chevron' />
                                    )}
                                  </span>
                                )}
                              </li>
                              {cSelected && (
                                <div className='d-flex flex-column'>
                                  {c?.children?.nodes?.map((childItem: any) => {
                                    return (
                                      <NavLink
                                        className='MenuResponsive__list-item-submenu-submenu-sub'
                                        key={childItem?.id}
                                        to={`/veshje-${category.slug}/${c?.slug}/${childItem?.slug}`}
                                      >
                                        {childItem?.name}
                                      </NavLink>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </ul>
                    ))}
                </div>
              );
            })}
          </>
          <div data-root='true' className='MenuResponsive__list-item'>
            <div className='MenuResponsive__list-item-toggle'>
              <NavLink to='/zbritje'>Zbritje</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
