import gql from "graphql-tag";

export const GET_COUPON = gql`
  query couponQuery {
    generateCoupon {
      coupon
      minutes
      percent
    }
    couponDiscountIsActive
  }
`;

export interface GetCouponType {
  couponDiscountIsActive: boolean;
  generateCoupon: {
    coupon: string;
    minutes: string;
    percent: string;
  };
}
