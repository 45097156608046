// import gql from "graphql-tag";
//
// export interface ProductArchiveItem {
//   id: string;
//   slug: string;
//   databaseId: number;
//   name: string;
//   regularPrice: string;
//   salePrice: string;
//   image: {
//     srcSet: string;
//     mediaItemUrl: string;
//   };
// }
//
// export interface CustomAttributeOption {
//   id: number;
//   slug: string;
//   name: string;
// }
//
// export interface ProductSingleAttributeProps {
//   name: string;
//   options: string[];
//   id: string;
//   scope: string;
//   visible: boolean;
//   customAttributesOptions: CustomAttributeOption[];
//   enabled_options?: (string | null)[];
// }
// export interface ProductSingleVariationProps {
//   [key: string]: any;
//   id: string;
//   variationId: number;
//   regularPrice: string;
//   salePrice: string;
//   sku: string;
//   onSale: boolean;
//   stockQuantity: number;
//   image?: {
//     slug?: string;
//   };
//   attributes: {
//     nodes: {
//       name: string;
//       value: string;
//     }[];
//   };
// }
//
// export interface ProductOptionTab {
//   title: string;
//   description: string;
// }
//
// export interface ProductOptions {
//   howToUse?: ProductOptionTab[];
//   ingridients?: ProductOptionTab;
//   benefits?: ProductOptionTab;
//   skintype?: ProductOptionTab;
//   qa?: ProductOptionTab;
// }
//
// export interface ProductSingleProps {
//   [key: string]: any;
//   id: string;
//   slug: string;
//   databaseId: number;
//   name: string;
//   description: string;
//   onSale: boolean;
//   sku: string;
//   type: string;
//   regularPrice: string;
//   salePrice: string;
//   stockQuantity: number;
//   image: {
//     slug?: string;
//     sourceUrl: string;
//   };
//   galleryImages: {
//     nodes: {
//       slug?: string;
//       sourceUrl: string;
//     }[];
//   };
//   productCategories: {
//     nodes: {
//       slug: string;
//     }[];
//   };
//   productBrands: {
//     nodes: {
//       id: string;
//       name: string;
//       slug: string;
//     }[];
//   };
//   productOptions: ProductOptions;
//   attributes: {
//     nodes: ProductSingleAttributeProps[];
//   };
//   variations: {
//     nodes: ProductSingleVariationProps[];
//   };
// }
// export const GET_ARCHIVE_PRODUCTS = gql`
//   query archiveQuery($limit: Int, $after: String) {
//     products(first: $limit, after: $after) {
//       nodes {
//         id
//         slug
//         databaseId
//         name
//         ... on VariableProduct {
//           name
//           regularPrice(format: FORMATTED)
//           salePrice(format: FORMATTED)
//         }
//         ... on SimpleProduct {
//           name
//           salePrice
//           regularPrice
//         }
//         image {
//           id
//           mediaItemUrl
//           sourceUrl(size: _300X210)
//         }
//       }
//       pageInfo {
//         endCursor
//         hasNextPage
//       }
//     }
//   }
// `;
//
// export const GET_RELATED_PRODUCTS = gql`
//   query archiveQuery($exclude: [Int], $category: String) {
//     products(where: { exclude: $exclude, category: $category }, first: 8) {
//       nodes {
//         id
//         slug
//         databaseId
//         name
//         ... on VariableProduct {
//           name
//           regularPrice(format: FORMATTED)
//           salePrice(format: FORMATTED)
//         }
//         ... on SimpleProduct {
//           name
//           salePrice
//           regularPrice
//         }
//         image {
//           id
//           mediaItemUrl
//           sourceUrl(size: _630X500)
//         }
//       }
//     }
//   }
// `;
//
// export const VARIABLE_PRODUCT_FIELDS = gql`
//   fragment VariableProductFields on VariableProduct {
//     name
//     stockQuantity
//     salePrice(format: FORMATTED)
//     regularPrice(format: FORMATTED)
//     variations(first: 1000) {
//       nodes {
//         id
//         sku
//         onSale
//         image {
//           slug
//         }
//         attributes {
//           nodes {
//             id
//             name
//             value
//           }
//         }
//         databaseId
//         stockStatus
//         stockQuantity
//         salePrice(format: FORMATTED)
//         regularPrice(format: FORMATTED)
//       }
//     }
//   }
// `;
//
// export const GET_SINGLE_PRODUCTS = gql`
//   query singleProduct($slug: String) {
//     products(where: { slug: $slug }) {
//       nodes {
//         id
//         slug
//         databaseId
//         name
//         description
//         onSale
//         sku
//         type
//         image {
//           id
//           slug
//           sourceUrl(size: MEDIUM_LARGE)
//         }
//         galleryImages {
//           nodes {
//             id
//             slug
//             sourceUrl(size: LARGE)
//           }
//         }
//         productCategories {
//           nodes {
//             id
//             slug
//           }
//         }
//         productBrands {
//           nodes {
//             id
//             name
//             slug
//           }
//         }
//
//         attributes {
//           nodes {
//             name
//             options
//             id
//             scope
//             visible
//             customAttributesOptions {
//               id
//               slug
//               name
//             }
//           }
//         }
//         ... on SimpleProduct {
//           name
//           stockStatus
//           stockQuantity
//           salePrice(format: FORMATTED)
//           regularPrice(format: FORMATTED)
//         }
//         ... on VariableProduct {
//           ...VariableProductFields
//         }
//       }
//     }
//   }
//   ${VARIABLE_PRODUCT_FIELDS}
// `;
// export const GET_USER_WISHLIST = gql`
//   query getUserWishlist($products: [Int]) {
//     products(first: 100, where: { include: $products }) {
//       nodes {
//         id
//         slug
//         name
//         databaseId
//         ... on VariableProduct {
//           name
//           regularPrice(format: FORMATTED)
//           salePrice(format: FORMATTED)
//         }
//         ... on SimpleProduct {
//           name
//           salePrice
//           regularPrice
//         }
//         image {
//           id
//           mediaItemUrl
//           sourceUrl(size: SHOP_CATALOG)
//         }
//       }
//     }
//   }
// `;
//
// export const GET_SEARCH_QUERY = gql`
//   query getSearchQuery($term: String, $limit: Int, $after: String) {
//     products(first: $limit, after: $after, where: { search: $term }) {
//       nodes {
//         id
//         slug
//         databaseId
//         name
//         ... on VariableProduct {
//           name
//           regularPrice(format: FORMATTED)
//           salePrice(format: FORMATTED)
//         }
//         ... on SimpleProduct {
//           name
//           salePrice
//           regularPrice
//         }
//         image {
//           id
//           mediaItemUrl
//           sourceUrl(size: _300X210)
//         }
//       }
//       pageInfo {
//         endCursor
//         hasNextPage
//       }
//     }
//   }
// `;

import gql from "graphql-tag";

export interface ProductArchiveItem {
  [key: string]: any;
  id: string;
  slug: string;
  databaseId: number;
  name: string;
  regularPrice: string;
  salePrice: string;
  image: {
    srcSet: string;
    mediaItemUrl: string;
  };
}

export interface CustomAttributeOption {
  [key: string]: any;

  id: number;
  slug: string;
  name: string;
}

export interface ProductSingleAttributeProps {
  [key: string]: any;

  name: string;
  options: string[];
  id: string;
  scope: string;
  visible: boolean;
  customAttributesOptions: CustomAttributeOption[];
  enabled_options?: (string | null)[];
}
export interface ProductSingleVariationProps {
  [key: string]: any;

  node: {
    id: string;
    databaseId: number;
    regularPrice: string;
    salePrice: string;
    sku: string;
    onSale: boolean;
    stockStatus: string;
    stockQuantity: number;
    image?: {
      slug?: string;
    };
    attributes: {
      nodes: {
        name: string;
        value: string;
      }[];
    };
  };
}

export interface ProductOptions {
  [key: string]: any;

  unit?: string;
  origin?: string;
  importer?: string;
  components?: string;
  composition?: string;
  manufacturer?: string;
}

export interface ProductSingleProps {
  [key: string]: any;

  id: string;
  slug: string;
  databaseId: number;
  name: string;
  description: string;
  onSale: boolean;
  sku: string;
  type: string;
  regularPrice: string;
  salePrice: string;
  stockQuantity: number;
  image: {
    slug?: string;
    sourceUrl: string;
  };
  galleryImages: {
    nodes: {
      slug?: string;
      sourceUrl: string;
    }[];
  };
  englishProductFields: {
    propertiesEn: string;
    componentsEn: string;
    compositionEn: string;
  };
  productCategories: {
    nodes: {
      slug: string;
    }[];
  };
  productBrands: {
    nodes: {
      id: string;
      name: string;
      slug: string;
    }[];
  };
  productOptions: ProductOptions;
  attributes: {
    nodes: ProductSingleAttributeProps[];
  };
  variations: {
    nodes: ProductSingleVariationProps[];
  };
}

export const VARIABLE_PRODUCT_FIELDS = gql`
  fragment VariableProductFields on VariableProduct {
    name
    stockQuantity
    salePrice(format: FORMATTED)
    regularPrice(format: FORMATTED)
    variations(first: 1000) {
      nodes {
        id
        sku
        onSale
        image {
          slug
        }
        attributes {
          nodes {
            id
            name
            value
          }
        }
        databaseId
        stockStatus
        stockQuantity
        salePrice(format: FORMATTED)
        regularPrice(format: FORMATTED)
      }
    }
  }
`;

export const SIMPLE_PRODUCT_FIELDS = gql`
  fragment SimpleProductFields on SimpleProduct {
    name
    width
    height
    length
    stockStatus
    stockQuantity
    salePrice(format: FORMATTED)
    regularPrice(format: FORMATTED)
    attributes {
      nodes {
        id
        name
        options
      }
    }
  }
`;

export const GET_ARCHIVE_PRODUCTS = gql`
  query archiveQuery($limit: Int, $after: String) {
    products(first: $limit, after: $after) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
          stockQuantity
          stockStatus
        }
        productBrands {
          nodes {
            id
            name
            slug
          }
        }
        image {
          id
          mediaItemUrl
          srcSet(size: LARGE)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        offsetPagination {
          total
        }
      }
    }
  }
`;

export const GET_RELATED_PRODUCTS = gql`
  query getRelatedProducts(
    $exclude: [Int]
    $category: String
    $after: String
    $limit: Int
  ) {
    products(
      first: $limit
      after: $after
      where: { exclude: $exclude, category: $category }
    ) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
          variations(first: 100) {
            nodes {
              id
              databaseId
              stockQuantity
              image {
                slug
              }
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
          stockQuantity
          stockStatus
        }
        productBrands {
          nodes {
            id
            name
            slug
          }
        }
        image {
          id
          slug
          mediaItemUrl
          srcSet(size: LARGE)
          sourceUrl
        }
        galleryImages(first: 100) {
          nodes {
            id
            slug
            sourceUrl(size: LARGE)
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        offsetPagination {
          total
        }
      }
    }
  }
`;

export const GET_SINGLE_PRODUCTS = gql`
  query singleProduct($slug: String) {
    products(where: { slug: $slug }) {
      nodes {
        id
        slug
        databaseId
        name
        description
        onSale
        sku
        type
        image {
          id
          slug
          sourceUrl(size: MEDIUM_LARGE)
        }
        galleryImages(first: 100) {
          nodes {
            id
            slug
            sourceUrl(size: LARGE)
          }
        }
        productCategories {
          nodes {
            id
            slug
          }
        }
        productBrands {
          nodes {
            id
            name
            slug
          }
        }

        ... on SimpleProduct {
          ...SimpleProductFields
        }
        ... on VariableProduct {
          ...VariableProductFields
        }
      }
    }
  }
  ${SIMPLE_PRODUCT_FIELDS}
  ${VARIABLE_PRODUCT_FIELDS}
`;

export const GET_USER_WISHLIST = gql`
  query getUserWishlist($products: [Int]) {
    products(first: 100, where: { include: $products }) {
      nodes {
        id
        slug
        name
        databaseId
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
          stockQuantity
          stockStatus
        }
        productBrands {
          nodes {
            id
            name
            slug
          }
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: LARGE)
        }
      }
    }
  }
`;

export const GET_SEARCH_QUERY = gql`
  query getSearchQuery($term: String, $limit: Int, $after: String) {
    products(first: $limit, after: $after, where: { search: $term }) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
          stockQuantity
          stockStatus
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _300X210)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
