import React from "react";
import { Logo } from "../Logo/Logo";
import { UNavigation } from "../UserNavigation/UNavigation";
import { Search } from "../Search/Search";
import { Menu } from "../Menu/Menu";

import "./InnerHeader.scss";

export const InnerHeader = () => {
  return (
    <div className="InnerHeader">
      <div className="InnerHeader__wrapper">
        <div className="InnerHeader__wrapper-left">
          <Logo />
        </div>
        <Menu />
        <div className="InnerHeader__wrapper-right">
          <div className="InnerHeader__wrapper-right--search">
            <Search />
            <UNavigation />
          </div>
        </div>
      </div>
    </div>
  );
};
