import React, { useEffect, useState } from "react";
import cs from "classnames";
import { useWishlist } from "../../../lib/hooks/useWishlist";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { useMenuSidebar } from "../../../lib/context/MenuSidebarContext/MenuSidebarContext";
import ReactPixel from "react-facebook-pixel";

import "./WishListButton.scss";

interface Props {
  id: number;
  name: string;
}

export const WishListButton = (props: Props) => {
  const menuCtx = useMenuSidebar();
  const { addToWishlist, isProductOnWishList } = useWishlist();
  const [isFavorite, setIsFavorite] = useState(isProductOnWishList(props.id));

  const handleClick = () => {
    addToWishlist(props.id);
    setIsFavorite(true);
    ReactPixel.track("AddToWishlist", {
      content_ids: props.id,
      content_name: props.name,
    });
  };

  useEffect(() => {
    if (!isProductOnWishList(props.id)) {
      setIsFavorite(false);
    }
  }, [menuCtx.wishtlist]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Button
      color="pink"
      className={cs("WishListButton", isFavorite && "WishListButton--fill")}
      onClick={handleClick}
    >
      <Icon icon="heart" />
    </Button>
  );
};
