import React, { useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";
import { errors } from "../../lib/translation/strings";

import "./ProductSingleVariations.scss";

export const ProductSingleVariations = () => {
  const { t } = useTranslation();
  const {
    attributes,
    updateSelectedAttributes,
    selectedAttributes,
  } = useProductSingleContext();

  // const size =
  //   attributes
  //     ?.filter((s: any) => s.name === "pa_madhesia")
  //     ?.map((s: any) => {
  //       const options = Array.from(s.values) as string[];
  //       return { value: options[0] };
  //     }) || [];

  // useEffect(() => {
  //   updateSelectedAttributes("pa_madhesia", size[0]?.value);
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="SingleProduct__variations_wrapper">
      {attributes.map((attr: any) => {
        const options = Array.from(attr.values) as string[];
        const isColorAttribute = attr.name === "pa_ngjyra";

        return (
          <div
            key={`${attr.name}-single-variations`}
            className="SingleProduct__content__variations"
          >
            <span className="label mr-5">{t(attr.name)}</span>
            {isColorAttribute ? (
              <ColorBoxes
                name={attr.name}
                options={options}
                onClick={(item: string) => {
                  updateSelectedAttributes(attr.name, item);
                }}
              />
            ) : (
              <div className="ShopSelect__dropdown">
                <ul className="ShopSelect__dropdown_list">
                  {!options || options.length === 0 ? (
                    <span>{t(errors.noOptions)}</span>
                  ) : (
                    options?.map((option: string, index) => {
                      const items =
                        selectedAttributes
                          ?.filter((s) => s.attribute !== "pa_ngjyra")
                          ?.map((s) => ({ value: s.value, label: s.value })) ||
                        [];

                      const target =
                        items && Array.isArray(items)
                          ? items.find((o) => {
                              return o.value === option;
                            })
                          : null;

                      return (
                        <li
                          key={`${option}-${index}`}
                          data-selected={target?.value === option}
                          className="ShopSelect__dropdown_list_item"
                          onClick={() =>
                            updateSelectedAttributes(attr.name, option)
                          }
                        >
                          {option}
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
              // <VariationSelect
              //   name={attr.name}
              //   options={options}
              //   onChange={(item: string) =>
              //     updateSelectedAttributes(attr.name, item)
              //   }
              // />
            )}
          </div>
        );
      })}
    </div>
  );
};

interface SelectProps {
  name: string;
  options: any;
  onChange: (value: string) => void;
}

// const VariationSelect = (props: SelectProps) => {
//   const { t } = useTranslation();
//   const { selectedAttributes } = useProductSingleContext();

//   const selected =
//     selectedAttributes
//       ?.filter((s) => s.attribute !== "pa_ngjyra")
//       ?.map((s) => ({ value: s.value, label: s.value })) || [];

//   return (
//     <Select
//       placeholder={t("Zgjidhni një opsion")}
//       className="ProductSingle__variation-select"
//       options={props.options.map((i: string) => ({ label: i, value: i }))}
//       onChange={(selectedOptions: any) => {
//         props.onChange(selectedOptions?.value || "");
//       }}
//       value={selected}
//     />
//   );
// };

interface ColorProps {
  name: string;
  options: any;
  onClick: (color: string) => void;
}

export const ColorBoxes = (props: ColorProps) => {
  const { t } = useTranslation();
  const { selectedAttributes } = useProductSingleContext();

  const selected =
    selectedAttributes
      ?.filter((s) => s.attribute !== "pa_madhesia")
      ?.map((s) => ({ value: s.value, label: s.value })) || [];

  useEffect(() => {
    if (props.options.length === 1) {
      props.onClick(props.options[0]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select
      placeholder={t("Zgjidhni një opsion")}
      className="SingleProduct__variation-select"
      options={props.options.map((i: string) => ({ label: i, value: i }))}
      onChange={(item: any) => {
        props.onClick(item.value || "");
      }}
      value={selected}
    />
  );
};
