import { useSearchParams } from "./useSearchParams";

const defaultField = "DATE";
const defaultOrder = "DESC";

export const useOrderFieldsFromSlug = () => {
  const searchParams = useSearchParams();

  const field =
    searchParams.order && typeof searchParams.order === "string"
      ? searchParams.order?.split("-")[0]
      : defaultField;

  const order =
    searchParams.order && typeof searchParams.order === "string"
      ? searchParams.order?.split("-")[1].startsWith("ASC")
        ? "ASC"
        : "DESC"
      : defaultOrder;

  return { field, order };
};
