import React from "react";

import "./ThreeCampaigns.scss";

export const ThreeCampaigns = () => {
  return (
    <div className='ThreeCampaigns_wrapper'>
      <h2 className='font-weight-bold'>Më shumë S//SPRINT</h2>
      <div className='ThreeCampaigns'>
        <div className='ThreeCampaigns-item'>
          <img src={require("../../assets/images/malesss.jpg")} alt='males' />
          <div className='ThreeCampaigns-item__text-wrapper'>
            <a
              type='button'
              className='ThreeCampaigns-item__text-wrapper-btn'
              href='/veshje-meshkuj'
            >
              Meshkuj
            </a>
          </div>
        </div>
        <div className='ThreeCampaigns-item'>
          <img src={require("../../assets/images/femalesss.jpg")} alt='females' />
          <div className='ThreeCampaigns-item__text-wrapper'>
            <a type='button' className='ThreeCampaigns-item__text-wrapper-btn' href='/veshje-femra'>
              Femra
            </a>
          </div>
        </div>
        <div className='ThreeCampaigns-item'>
          <img src={require("../../assets/images/kidsss.jpg")} alt='kids' />
          <div className='ThreeCampaigns-item__text-wrapper'>
            <a
              type='button'
              className='ThreeCampaigns-item__text-wrapper-btn'
              href='/veshje-femije'
            >
              Fëmijë
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
