import React from "react";
import { Range } from "rc-slider";

import "./PriceRange.scss";
import "rc-slider/assets/index.css";

interface PriceRangeProps {
  state: {
    min: number;
    max: number;
  };
  handleChange: (value: number[]) => void;
  handleAfterOnChange: (value: number[]) => void;
}

const PriceRange = (props: PriceRangeProps) => {
  const { state, handleChange, handleAfterOnChange } = props;

  const hanldeStyle = {
    borderColor: "#ef3636",
    boxShadow: "none",
    backgroundColor: "white",
  };

  return (
    <div className="PriceRange">
      <Range
        handleStyle={[hanldeStyle, hanldeStyle]}
        railStyle={{ backgroundColor: "#deded" }}
        trackStyle={[{ backgroundColor: "#ef3636" }]}
        allowCross={false}
        min={0}
        max={state.max}
        defaultValue={[state.min, state.max]}
        onChange={handleChange}
        onAfterChange={handleAfterOnChange}
      />
      <div className="PriceRange__min-max">
        <span>Cmimi:</span>
        <div className="PriceRange__min-max-wrapper">
          <span>€ {state.min} - </span>
          <span>€ {state.max}</span>
        </div>
      </div>
    </div>
  );
};

export default PriceRange;
