import React from "react";
import cs from "classnames";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";

import { errors } from "../../lib/translation/strings";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

import { Product } from "./Product";
import { ProductsLoader } from "./ListOfProductsLoader";
import { BulkLoader } from "../shared/BulkLoader/BulkLoader";
import { LoadMoreButton } from "../shared/LoadMoreButton/LoadMoreButton";
import { InfoMessage } from "../shared/InfoMessage/InfoMessage";
import { HandleLoadingState } from "../shared/HandleLoadingState/HandleLoadingState";

import { ProductProps } from "../../types/productTypes";

import "./ListOfProducts.scss";

interface Props {
  big?: boolean;
  className?: string;
  error?: string;
  loading?: boolean;
  products?: ProductProps[];
  isLoading: boolean;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  pageInfo: boolean;
  loadingPlaceholderNumber?: number;

  onLoadPrevious?: () => void;
  onLoadMore: () => void;
}

export const ListOfProducts = (props: Props) => {
  const { t } = useTranslation();
  const noProductsAvailable = !props.products || Number(props.products.length) === 0;
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  return (
    <div className='ListOfProducts__wrapper'>
      {props.hasPreviousPage && (
        <div className='mb-4'>
          <LoadMoreButton
            btnLabel='Load previous'
            loading={props.loading || props.isLoading}
            onClick={() => {
              if (props.onLoadPrevious) {
                props.onLoadPrevious();
              }
            }}
          />
        </div>
      )}

      <HandleLoadingState
        loading={props.loading}
        loadingPlaceholder={
          isMobile ? (
            <div className='d-flex align-items-center w-100 justify-content-center'>
              <Spinner color='black' size='lg' />
            </div>
          ) : (
            <div className='ListOfProducts'>
              <BulkLoader length={props.loadingPlaceholderNumber || 6} component={ProductsLoader} />
            </div>
          )
        }
      >
        <div
          className={cs(
            "ListOfProducts",
            !props.loading && noProductsAvailable && "ListOfProducts--empty",
            props.className,
          )}
        >
          {noProductsAvailable ? (
            <InfoMessage
              title={t(errors.noProducts)}
              description={t(errors.noProductsDescription)}
              buttonLink='/blej-online'
            />
          ) : (
            props.products?.map((product) => {
              return <Product key={product.id} product={product} />;
            })
          )}
        </div>
      </HandleLoadingState>
      {props.pageInfo && (
        <LoadMoreButton onClick={props.onLoadMore} loading={props.loading || props.isLoading} />
      )}
    </div>
  );
};
