import React from "react";
// import cs from "classnames";
import { useTranslation } from "react-i18next";

import { useShopContext } from "../../../lib/context/ShopContext/ShopContext";

import { fields } from "../../../lib/translation/strings";
import { ShopProductCategory } from "../../../queries/shop";

import "./ShopCategories.scss";

interface Props {
  categoryTitle?: string;
  categories: ShopProductCategory[];
  onCategoryChange: (categorySlug: string) => void;
}

export const ShopCategories = (props: Props) => {
  const { t } = useTranslation();
  const shopCtx = useShopContext();

  const handleCategoryChange = (categorySlug: string) => {
    props.onCategoryChange(categorySlug);
  };

  const mobileSelectOptions: any = [
    { value: "/", name: t(fields.viewAll), isPlaceholder: true, slug: "" },
    ...props.categories,
  ];

  const isActive = shopCtx.data?.category;

  return (
    <div className="ShopCategories">
      <h4 className="ShopCategories__title">Kategoritë</h4>
      {/* <div
        className={cs(
          "ShopCategories__category",
          "ShopCategories__category_all"
        )}
        onClick={() => handleCategoryChange("")}
        data-active={shopCtx.data?.category === undefined}
      >
        {t(fields.viewAll)}
      </div> */}
      <div className="ShopCategories__select">
        <select
          id="qty"
          name="qty"
          value={isActive || ""}
          onChange={(e) => handleCategoryChange(e.target.value)}
        >
          {mobileSelectOptions?.map((item: any) => (
            <option value={item.slug} key={`${item.id}-quantity`}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      {/* {props.categories &&
        props.categories.map((category, index) => {
          const isActive = shopCtx.data?.category === category.slug;
          return (
            <div
              key={`${category.slug}-${index}`}
              className={cs("ShopCategories__category")}
              onClick={() => handleCategoryChange(category.slug)}
              data-active={isActive}
            >
              {category.name}
            </div>
          );
        })} */}
    </div>
  );
};
