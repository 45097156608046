import React from "react";
import { useQuery } from "react-apollo";
import Slider from "react-slick";
import {
  GET_TYPE_A_CAMPAIGNS,
  CampaignTypeAPost,
} from "../../../queries/campaignsQueries";
import { SlideLoader } from "./SlideLoader";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { Icon } from "../../shared/Icon/Icon";

import "./Slide.scss";

const CustomArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "rgba(238, 238, 238, 0.8)",
        borderRadius: 3,
        "&:hover": {
          borderColor: "#ff681c",
        },
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-right" style={{ color: "#ef3636" }} />
    </span>
  );
};

const CustomArrowLeft = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "rgba(238, 238, 238, 0.8)",
        borderRadius: 3,
        "&:hover": {
          borderColor: "#ff681c",
        },
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" style={{ color: "#ef3636" }} />
    </span>
  );
};

export const Slide = () => {
  const slider = useQuery(GET_TYPE_A_CAMPAIGNS);

  const sliderData =
    slider.data?.generalOptions?.generalOptions?.homeAds
      ?.a1BannerSliderCampaigns ?? [];

  const settings = {
    infinite: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    fade: false,
    prevArrow: <CustomArrowLeft />,
    nextArrow: <CustomArrow />,
  };

  return (
    <div className="Slide">
      <HandleLoadingState
        loading={slider.loading}
        loadingPlaceholder={<SlideLoader />}
      >
        <ApolloErrorGuard error={slider.error}>
          <Slider {...settings}>
            {sliderData.map((item: CampaignTypeAPost) => {
              return (
                <div key={item.id} className="Slide__item">
                  <img src={item?.featuredImage?.node?.mediaItemUrl} alt="" />
                  {/* eslint-disable-next-line */}
                  <a
                    href={item.campaignsOptions.link}
                    className="Slide__content"
                  />
                </div>
              );
            })}
          </Slider>
        </ApolloErrorGuard>
      </HandleLoadingState>

      {/* <Button
        color="primary"
        className="Slide__btn-down"
        onClick={handleOnScrollDown}
      >
        <Icon icon="chevron-down" />
      </Button> */}
    </div>
  );
};
