import { useQuery } from "@apollo/react-hooks";
import React from "react";
import ReactPlayer from "react-player";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";
import { GET_TYPE_C_CAMPAIGNS } from "../../queries/campaignsQueries";
import { SlideLoader } from "../home/HomepageSlide/SlideLoader";
import { HandleLoadingState } from "../shared/HandleLoadingState/HandleLoadingState";

import "./Video.scss";

export const Video = () => {
  const { data, loading, error } = useQuery(GET_TYPE_C_CAMPAIGNS);
  const windowWidth = useWindowWidth();

  // const isTablet = windowWidth <= 1024;
  const isMobile = windowWidth <= 768;

  const video =
    data?.generalOptions?.generalOptions?.homeAds?.c1FullCampaign ?? [];

  const videoHeight = isMobile ? 220 : 700;

  if (error) {
    return null;
  }

  return (
    <HandleLoadingState loading={loading} loadingPlaceholder={<SlideLoader />}>
      <div className="Video">
        {/* <h2 className="font-weight-bold">Video</h2> */}
        <ReactPlayer
          width="100%"
          height={videoHeight}
          stopOnUnmount
          loop
          playing
          url={video?.campaignsOptions?.video?.mediaItemUrl}
          volume={0}
          muted
          playsinline
        />
      </div>
    </HandleLoadingState>
  );
};
