import React from "react";
import { Routes } from "./routes/Routes";
import { useScrollToTop } from "./lib/hooks/useScrollToTop";
import { useMenuSidebar } from "./lib/context/MenuSidebarContext/MenuSidebarContext";
import { SearchSidebar } from "./layout/SearchSidebar/SearchSidebar";
import { MenuContextProvider } from "./lib/context/MenuContext/MenuContextProvider";
import { NotificationContextProvider } from "./lib/context/NotificationContext/NotificationContextProvider";

import { Header } from "./layout/Header/Header";
import { Footer } from "./layout/Footer/Footer";
import { CartSidebar } from "./layout/CartSidebar/CartSidebar";
import { WishlistSidebar } from "./layout/WishlistSidebar/WishlistSidebar";
import { MenuResponsive } from "./layout/Header/Menu/MenuResponsive/MenuResponsive";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import WelcomeModal from "./components/shared/WelcomeModal/WelcomeModal";

import "./App.scss";

// import { Advertisement } from "./components/Advertisement/Advertisement";
 
const options = {
  autoConfig: true,
  debug: true,
};

export const App = () => {
  useScrollToTop();
  const menuSidebar = useMenuSidebar();
  const tagManagerArgs = {
    gtmId: "GTM-KRXNHQG",
  };

  TagManager.initialize(tagManagerArgs);

  React.useEffect(() => {
    ReactPixel.init("595342432039711", undefined, options);
    ReactPixel.pageView();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnClick = () => {
    menuSidebar.closeSidebar("wishlist");
    menuSidebar.closeSidebar("cart");
  };

  return (
    <div className='App' id='App'>
      <NotificationContextProvider>
        <div className='App__backdrop' onClick={handleOnClick} />
        <MenuContextProvider>
          <Header />
          <MenuResponsive />
        </MenuContextProvider>
        <div className='Main_content'>
          <Routes />
        </div>
        <Footer />
        <WishlistSidebar />
        <CartSidebar />
        <SearchSidebar />
        <WelcomeModal />
      </NotificationContextProvider>
    </div>
  );
};
