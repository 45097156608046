import { useEffect, useState } from "react";
import React from "react";

interface CountdownTimer {
  initialMinutes: string;
}

export const CountdownTimer = ({ initialMinutes }: CountdownTimer) => {
  const [time, setTime] = useState(parseInt(initialMinutes, 10) * 60); // Convert minutes to seconds

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    if (time <= 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [time]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div>
      <h3>{formatTime(time)}</h3>
    </div>
  );
};
