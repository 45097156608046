import React, { ReactNode } from "react";
import { Col, Row } from "reactstrap";
import classNames from "classnames";
import { SidebarContextProvider } from "../../../lib/context/SidebarContext/SidebarContextProvider";
// import { ShopMobileFilters } from "../ShopMobileFilters/ShopMobileFilters";
// import { MobileFiltersContextProvider } from "../../../lib/context/MobileFiltersContext/MobileFiltersContextProvider";
import { Container } from "../../Container/Container";

import "./Layout.scss";

interface Props {
  sidebar: ReactNode;
  content: ReactNode;
  head?: ReactNode;
  className?: string;
}

export const Layout = (props: Props) => {
  return (
    <div className={classNames("Layout", props.className)}>
      <SidebarContextProvider>
        {/*<MobileFiltersContextProvider>*/}
        {/*<ShopMobileFilters />*/}

        <div className="Layout__content">
          <Container className="p-0-mobile">
            <Row>
              <Col xs="12" md="2">
                {/* {props.head && (
                  <div className="ShopLayout__head">{props.head}</div>
                )} */}

                {props.sidebar}
              </Col>
              <Col xs="12" md="10" style={{ marginTop: 0 }}>
                {props.content}
              </Col>
            </Row>
          </Container>
        </div>
        {/*</MobileFiltersContextProvider>*/}
      </SidebarContextProvider>
    </div>
  );
};
