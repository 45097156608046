import React from "react";
import { ThemeLink } from "../../../components/shared/ThemeLink/ThemeLink";
import { Icon } from "../../../components/shared/Icon/Icon";
import { useMenuContext } from "../../../lib/context/MenuContext/MenuContext";
import { ReactComponent as LogoSvg } from "../../../assets/images/logo.svg";

import "./Logo.scss";

interface Props {
  noLink?: boolean;
  loading?: boolean;
}

export const Logo = (props: Props) => {
  const menuCtx = useMenuContext();

  return (
    <div className="Logo">
      {!props.loading && (
        <span
          role="button"
          className="Logo__mobile-menu"
          onClick={menuCtx.openSidebar}
        >
          <Icon icon="menu" />
        </span>
      )}
      {props.noLink ? (
        <LogoSvg width={256} height={201} />
      ) : (
        <ThemeLink to="/">
          <LogoSvg width={256} height={79} />
        </ThemeLink>
      )}
    </div>
  );
};
