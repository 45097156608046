import React from "react";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { UserCart } from "./UserCart";
import { ThemeLink } from "../../../components/shared/ThemeLink/ThemeLink";
import { Icon } from "../../../components/shared/Icon/Icon";
import { ReactComponent as User } from "../../../assets/icons/user.svg";
import { useMenuSidebar } from "../../../lib/context/MenuSidebarContext/MenuSidebarContext";
import { useWishlist } from "../../../lib/hooks/useWishlist";

import "./UNavigation.scss";

export const UNavigation = () => {
  const authCtx = useAuthContext();
  const menuSidebar = useMenuSidebar();
  const wishList = useWishlist();

  const count = wishList.getProductsIDs().length;

  return (
    <div className="UNavigation">
      {/*<span*/}
      {/*  role="button"*/}
      {/*  className="UNavigation__item UNavigation__item--search"*/}
      {/*>*/}
      {/*  <span role="button" onClick={() => menuSidebar.openSidebar("search")}>*/}
      {/*    <Icon icon="search" />*/}
      {/*    <span className="UNavigation__item__label">Kërko</span>*/}
      {/*  </span>*/}
      {/*</span>*/}
      <div className="UNavigation__item UNavigation__item--login">
        {authCtx.isAuthenticated ? (
          <ThemeLink
            to="/my-profile"
            activeClassName="UNavigation__item--active"
          >
            <span className="UNavigation__item--wishlist-icon">
              <User color="black" width={24} height={24} />
            </span>
            {/* <span className="UNavigation__item__label">
              <span className="UNavigation__item--wishlist-name">
                {authCtx?.user?.firstName?.toLocaleLowerCase()}{" "}
                {authCtx?.user?.lastName?.toLocaleLowerCase()}
              </span>
            </span> */}
          </ThemeLink>
        ) : (
          <ThemeLink to="/login" activeClassName="UNavigation__item--active">
            <span className="UNavigation__item--wishlist-icon">
              <User color="black" width={24} height={24} />
            </span>
            {/* <span className="UNavigation__item__label">Kyçu</span> */}
          </ThemeLink>
        )}
      </div>

      <span
        role="button"
        onClick={() => menuSidebar.openSidebar("wishlist")}
        className="UNavigation__item UNavigation__item--wishlist"
      >
        <span className="UNavigation__item--wishlist-icon">
          <Icon icon="heart" />
          {count && count > 0 ? (
            <div className="UNavigation__item--wishlist-icon-number">
              {count}
            </div>
          ) : null}
        </span>
        {/* <span className="UNavigation__item__label">Lista e deshirave</span> */}
      </span>

      <UserCart className="UNavigation__item" />

      {authCtx.isAuthenticated && (
        <span
          className="UNavigation__item UNavigation__item--wishlist"
          role="button"
          onClick={() => authCtx.logout()}
        >
          <span role="button">
            <Icon icon="log-out" />
          </span>
          {/* <span className="UNavigation__item__label">Dil</span> */}
        </span>
      )}
    </div>
  );
};
