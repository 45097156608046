import qs from "query-string";
import { useRouter } from "./useRouter";

export const useSearchParams = () => {
  const router = useRouter();
  const gender = (router.match.params as any).gjinia;
  let search = router.location.search;
  if (gender && ["meshkuj", "femra", "femije"].some((g) => gender.includes(g))) {
    const genderSplit = gender.split("-");
    search = search ? search + `&PAGJINIA=${genderSplit[0]}` : `?PAGJINIA=${genderSplit[0]}`;
    if (genderSplit.length > 1) {
      genderSplit.shift();
      const size = genderSplit.join("-");
      search = search + `&PAMADHESIA=${size}`;
    }
  } else if (gender) {
    search = search ? search + `&PAMADHESIA=${gender}` : `?PAMADHESIA=${gender}`;
  }
  return qs.parse(search);
};
