import React from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { Container as ReactContainer, Spinner } from "reactstrap";

import { errors } from "../../lib/translation/strings";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

import { Alert } from "../../components/Notifications/Alert";
import { RelatedProducts } from "../../components/RelatedProducts/RelatedProducts";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { ProductSingleContextProvider } from "../../lib/context/ProductSingleContext/ProductSingleContextProvider";
import { ProductSimpleAttributes } from "../../components/ui/ProductSimpleAttributes";
import { ProductSingleCarousel } from "../../components/ProductSingleCarousel/ProductSingleCarousel";
import { ProductSingleVariations } from "../../components/ProductSingleVariations/ProductSingleVariations";
import { SingleProductContent } from "../../components/ProductSingleContent/ProductSingleContent";
import { SingleProductDetails } from "../../components/SingleProductDetails/SingleProductDetails";
import { SingleProductButtons } from "../../components/SingleProductButtons/SingleProductButtons";
import { AmazonLoader } from "./AmazonLoader";
import { Container } from "../../components/Container/Container";

const ProductSingleInner = () => {
  const { t } = useTranslation();
  const { loading, error, product } = useProductSingleContext();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  return (
    <>
      <div className={cs("SingleProduct")}>
        <Container>
          <HandleLoadingState
            loading={loading}
            loadingPlaceholder={
              isMobile ? (
                <div className="d-flex align-items-center w-100 justify-content-center mb-5">
                  <Spinner color="black" size="lg" />
                </div>
              ) : (
                <ReactContainer>
                  <AmazonLoader />
                </ReactContainer>
              )
            }
          >
            <ApolloErrorGuard error={error}>
              <ReactContainer>
                {!product ? (
                  <Alert type="warning" message={t(errors.noProductFound)} />
                ) : (
                  <div className="SingleProduct__wrapper">
                    <ProductSingleCarousel />
                    <div className="SingleProduct__content">
                      <SingleProductContent />
                      {product.type === "VARIABLE" && (
                        <ProductSingleVariations />
                      )}
                      {product.type === "SIMPLE" && <ProductSimpleAttributes />}
                      <SingleProductButtons />
                      <SingleProductDetails />
                    </div>
                  </div>
                )}
              </ReactContainer>
              <RelatedProducts
                excludedID={product?.databaseId}
                category={product?.productCategories.nodes[0]?.slug || ""}
              />
            </ApolloErrorGuard>
          </HandleLoadingState>
        </Container>
      </div>
    </>
  );
};

export const ProductSingle = () => {
  return (
    <ProductSingleContextProvider>
      <ProductSingleInner />
    </ProductSingleContextProvider>
  );
};
