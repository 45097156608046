import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_TYPE_B_CAMPAIGNS } from "../../queries/campaignsQueries";

import { Slide } from "../../components/home/HomepageSlide/Slide";
import { Campaigns } from "../../components/home/Campaigns/Campaigns";
import { OnSale } from "../../components/home/HomeTabs/tabs/OnSale";
import { NewIn } from "../../components/home/HomeTabs/tabs/NewIn";
import { TopSale } from "../../components/home/HomeTabs/tabs/TopSale";
import { Video } from "../../components/Video/Video";
import { ThreeCampaigns } from "../../components/ThreeCampaigns/ThreeCampaigns";
import { Container } from "../../components/Container/Container";

import "./Home.scss";

export const Home = () => {
  const slider = useQuery(GET_TYPE_B_CAMPAIGNS);

  return (
    <div className="Home">
      <Container>
        <Slide />
        <NewIn />
        <Campaigns
          title=""
          campaign={
            slider.data?.generalOptions?.generalOptions?.homeAds
              ?.b1GridCampaigns
          }
        />
        <OnSale />
        <Video />
        <TopSale />
        <Campaigns
          title="Në trend"
          campaign={
            slider.data?.generalOptions?.generalOptions?.homeAds
              ?.b2GridCampaigns
          }
        />
        <ThreeCampaigns />
      </Container>
    </div>
  );
};
