import React, { useState } from "react";
import { Container } from "reactstrap";
import { ProductProps } from "../../types/productTypes";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { HandleNoItemsState } from "../../components/shared/HandleNoItemsState/HandleNoItemsState";

import { useWishlist, WishlistAttributes } from "../../lib/hooks/useWishlist";

import { WishlistLoader } from "./WishlistLoader";
import { BulkLoader } from "../../components/shared/BulkLoader/BulkLoader";

import { EmptyPage } from "../../components/shared/EmptyMessage/EmptyPage";
import { Product } from "../../components/ListOfProducts/Product";
import { HeadingTitle } from "../../components/shared/HeadingTitle/HeadingTitle";
import { HeadingTitleLoader } from "../../components/shared/HeadingTitle/HeadingTitleLoader";

import "./Wishlist.scss";

export const Wishlist = () => {
  const wishlist = useWishlist();

  const [state, setState] = useState<WishlistAttributes>({
    loading: true,
    error: undefined,
    data: [],
  });

  React.useEffect(() => {
    wishlist.getUserWishlist().then((res: WishlistAttributes | undefined) => {
      if (res) {
        setState({ ...res });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRemove = async (id: number) => {
    try {
      await wishlist.removeItem(id);

      setState((prev) => {
        return {
          ...prev,
          data: state.data?.filter((item: any) => item.databaseId !== id),
        };
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="Wishlist">
      <Container className="p-0-mobile">
        <ApolloErrorGuard error={state.error}>
          <HandleLoadingState
            loading={state.loading}
            loadingPlaceholder={
              <>
                <HeadingTitleLoader />
                <div className="Wishlist__list mt-5">
                  <BulkLoader length={8} component={WishlistLoader} />
                </div>
              </>
            }
          >
            <HandleNoItemsState
              hasItems={state.data.length > 0}
              placeholder={
                <EmptyPage
                  title="Lista ime"
                  buttonText="shto produkte"
                  description="Ju momentalisht nuk keni ndonjë produkt në listë"
                />
              }
            >
              <HeadingTitle icon="heart" title="Lista ime" />

              <div className="Wishlist__list">
                {state.data.map((item: ProductProps) => (
                  <Product
                    key={item.id}
                    product={item}
                    noWishlistButton={true}
                    onRemove={() => handleRemove(item.databaseId)}
                  />
                ))}
              </div>
            </HandleNoItemsState>
          </HandleLoadingState>
        </ApolloErrorGuard>
      </Container>
    </div>
  );
};
