import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { Container, Row, Col, Spinner } from "reactstrap";
import CheckoutLoader from "./CheckoutLoader";

import { useWindowWidth } from "../../lib/hooks/useWindowWidth";
import { useAddressQuery } from "../../lib/hooks/useAddressQuery";
import { useCartContext } from "../../lib/context/CartContext/CartContext";

import { BulkLoader } from "../../components/shared/BulkLoader/BulkLoader";
import { SingleProductLoader } from "../SingleProduct/SingleProductLoader";
import { ThemeRedirect } from "../../components/shared/ThemeRedirect/ThemeRedirect";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { CheckoutForm } from "../../components/Checkout/CheckoutForm/CheckoutForm";

import "./Checkout.scss";

export const Checkout = () => {
  const cartCtx = useCartContext();
  const addressQuery = useAddressQuery();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  useEffect(() => {
    ReactPixel.track("ViewContent", {
      content_name: "Checkout",
    });
  }, []);

  if (cartCtx.isEmpty) {
    return <ThemeRedirect to="/my-cart" />;
  }

  return (
    <div className="Checkout">
      <Container>
        <HandleLoadingState
          loading={addressQuery.loading}
          loadingPlaceholder={
            isMobile ? (
              <div className="d-flex align-items-center w-100 justify-content-center mb-5">
                <Spinner color="black" size="lg" />
              </div>
            ) : (
              <Row>
                <Col xs="12" md="6">
                  <BulkLoader length={1} component={SingleProductLoader} />
                </Col>
                <Col xs="12" md="6">
                  <CheckoutLoader />
                </Col>
              </Row>
            )
          }
        >
          <ApolloErrorGuard error={addressQuery.error}>
            <CheckoutForm customer={addressQuery.data?.customer} />
          </ApolloErrorGuard>
        </HandleLoadingState>
      </Container>
    </div>
  );
};
