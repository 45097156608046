import { ProductVariation } from "../../types/productTypes";

export const getVariableProductStock = (variations?: ProductVariation[]) => {
  const stockQuantities: any = [];

  variations?.map((variation) => {
    if (variation.stockQuantity) {
      return stockQuantities.push(variation.stockQuantity);
    }
    return stockQuantities.push(0);
  });

  const getSum = (a: number, b: number) => {
    return a + b;
  };

  const getAverage =
    stockQuantities.length > 0
      ? stockQuantities?.reduce(getSum) / stockQuantities.length
      : 0;

  return getAverage;
};
