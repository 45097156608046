import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { ReactComponent as Placeholder } from "../../assets/icons/placeholder.svg";
import { ReactComponent as Smartphone } from "../../assets/icons/smartphone.svg";
import { ReactComponent as Money } from "../../assets/icons/money.svg";
import { ReactComponent as Truck } from "../../assets/icons/delivery-truck.svg";
import { ReactComponent as Trophy } from "../../assets/icons/trophy.svg";
import { FeedbackModal } from "../../components/Feedback/FeedbackModal";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import "./Footer.scss";
import { Container } from "reactstrap";

export interface Feedback {
  feedback: string;
  message: string;
  review: number;
}

export const Footer = () => {
  const history = useHistory();
  const authCtx = useAuthContext();
  const [open, setOpen] = useState(false);

  return (
    <div className="Footer">
      {history.location.pathname === "/" && (
        <div className="Footer__desktop">
          <Container>
            <div className="Footer__desktop_wrapper">
              <div className="Footer__desktop_element">
                <div className="Footer__desktop_element-icon">
                  <Truck />
                </div>
                <div className="Footer__desktop_element-text">
                  <h1>
                    <span>POROSIT </span>
                    ONLINE
                  </h1>
                  <span>Transport në të gjihtë Kosovën.</span>
                </div>
              </div>
              <div className="Footer__desktop_element">
                <div className="Footer__desktop_element-icon">
                  <Money />
                </div>
                <div className="Footer__desktop_element-text">
                  <h1>
                    <span>PAGUAJ PAS PRANIMIT </span>
                    TË MALLIT
                  </h1>
                  <span>Deri n'derë të shtëpisë!</span>
                </div>
              </div>
              <div className="Footer__desktop_element">
                <div className="Footer__desktop_element-icon">
                  <Trophy />
                </div>
                <div className="Footer__desktop_element-text">
                  <h1>
                    <span>KUALITET I </span>
                    GARANTUAR
                  </h1>
                  <span>Prodhim vendor e kualitet i lartë.</span>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
      <Container>
        <div className="Footer__items">
          <ul className="Footer__list">
            <li className="Footer__list-head">Informata</li>
            <li>
              <Link to="/">Ballina</Link>
            </li>
            <li>
              <Link to="/contact">Kontakti</Link>
            </li>
            {authCtx.isAuthenticated && (
              <li>
                <Link to="/jobs">Pune</Link>
              </li>
            )}
            <li>
              <Link to="/page/politikat-e-privatesise">Per ne</Link>
            </li>
            <li onClick={() => setOpen(true)}>
              <span>Feedback</span>
            </li>
          </ul>
          {/* <ul className="Footer__list">
            <li className="Footer__list-head">Social Media</li>
            <li style={{ display: "flex" }}>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="facebook" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-3"
              >
                <Icon icon="instagram" />
              </a>
            </li>
          </ul> */}

          <ul className="Footer__list">
            <li className="Footer__list-head">Kontakt</li>
            <li>
              <a href="mailto:info@s-sprint.net">
                Për cdo gjë mund të na kontaktoni në info@s-sprint.net
              </a>
            </li>
            <li className="Footer__list-location">
              <Placeholder width={24} height={32} />
              <a href="/contact">
                Zyra qendrore Rr. Elez Berisha Fushë Kosovë - Kosovë
              </a>
            </li>
            <li className="Footer__list-phone">
              <Smartphone width={24} height={32} />
              <a href="tel:+38349279649">+383 (0) 49 279 649</a>
            </li>
          </ul>
        </div>
      </Container>
      <div className="Footer__copyright">
        <span>
          Copyright © {new Date().getFullYear()} S//SPRINT. All Rights Reserved.
        </span>
      </div>
      <FeedbackModal open={open} toggle={() => setOpen(false)} />
    </div>
  );
};
