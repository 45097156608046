import React from "react";
import { useTranslation } from "react-i18next";

import { actions } from "../../lib/translation/strings";

import { CheckoutFormik } from "../../lib/formik/useCheckoutFormik";

import "./Cart.scss";
import { NavLink } from "react-router-dom";
import { Icon } from "../../components/shared/Icon/Icon";
import { Button } from "../../components/shared/Button/Button";
import { Spinner } from "reactstrap";

interface Props {
  formik?: CheckoutFormik;
}

export const CartTotal = (props: Props) => {
  const { t } = useTranslation();

  const handleOnClick = () => {
    if (props.formik?.isSubmitting || !props.formik) {
      return;
    }

    props.formik.submitForm();
  };

  const buttonTitle = props.formik?.isSubmitting
    ? t(actions.processing)
    : t(actions.continueWithPurchase);

  return (
    <div className="CartTotal">
      {props.formik ? (
        <Button
          color="success"
          title={buttonTitle}
          onClick={handleOnClick}
          className="CartTotal-submit"
        >
          {props.formik?.isSubmitting ? (
            <>
              Ju lutem prisni <Spinner className="ml-2" />
            </>
          ) : (
            <>
              Vazhdo me blerjen{" "}
              <Icon
                style={{
                  width: 16,
                  height: 16,
                  marginLeft: 10,
                  marginTop: 3,
                }}
                icon="arrow-right"
                className="ml-2"
              />
            </>
          )}
        </Button>
      ) : (
        <div className="CartSidebar-footer">
          <NavLink to="/checkout">
            Vazhdo me blerjen{" "}
            <Icon
              style={{
                width: 16,
                height: 16,
                marginLeft: 10,
                marginTop: 3,
              }}
              icon="arrow-right"
            />
          </NavLink>
        </div>
      )}
    </div>
  );
};
