import React from "react";
import ContentLoader from "react-content-loader";

import "./ListOfProducts.scss";

export const ProductsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={381}
      height={282}
      viewBox="0 0 381 282"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="ProductsLoader"
    >
      <rect x="0" y="0" width="381" height="282" />
    </ContentLoader>
  );
};
