import { useQuery } from "react-apollo";
import * as queries from "../../../../queries/shop";

export const useFetchMenu = () => {
  const { data, loading, error } = useQuery<any, queries.getMenuVariables>(
    queries.GET_MENU
  );

  return {
    categories: data?.paGjinias?.nodes,
    loading,
    error,
  };
};
