import { useQuery } from "@apollo/react-hooks";
import React from "react";
import {
  CampaignTypeAPost,
  GET_TYPE_B_CAMPAIGNS,
} from "../../../queries/campaignsQueries";
import { Container } from "../../Container/Container";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { SlideLoader } from "../HomepageSlide/SlideLoader";

import "./Campaigns.scss";

interface CampaignsProps {
  campaign: CampaignTypeAPost[];
  title: string;
}

export const Campaigns = (props: CampaignsProps) => {
  const slider = useQuery(GET_TYPE_B_CAMPAIGNS);

  const campaigns = props.campaign ?? [];

  return (
    <Container>
      <HandleLoadingState
        loading={slider.loading}
        loadingPlaceholder={<SlideLoader />}
      >
        <div className="Campaigns_wrapper">
          <h2 className="font-weight-bold">{props.title}</h2>
          <div className="Campaigns">
            {campaigns?.map((item: CampaignTypeAPost) => {
              return (
                <div key={item.id} className="Campaigns-item">
                  <img
                    src={item.featuredImage?.node?.mediaItemUrl}
                    alt={item.slug}
                  />
                  <div className="Campaigns-item__text-wrapper">
                    <p>{item.campaignsOptions.title}</p>
                    <a
                      type="button"
                      className="Campaigns-item__text-wrapper-btn"
                      href={item.campaignsOptions?.link}
                    >
                      {item.campaignsOptions?.text}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </HandleLoadingState>
    </Container>
  );
};
