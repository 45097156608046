import React, { useState } from "react";
import { ThemeLink } from "../shared/ThemeLink/ThemeLink";
import { ProductProps } from "../../types/productTypes";
import { Image } from "../shared/Image/Image";
import { RemoveItemButton } from "../RemoveItemButton/RemoveItemButton";
import { WishListButton } from "../shared/WishListButton/WishListButton";
// import { ProductWatermark } from "./ProductItemWatermark";
import { getProductImages } from "../../pages/SingleProduct/lib/helpers/getProductImages";
import cs from "classnames";
import { getVariableProductStock } from "../../lib/helpers/getVariableProductStock";
import { ProductPrices } from "../shared/ProductPrices/ProductPrices";
import { ProductPricesTemporary } from "../shared/ProductPricesTemporary/ProductPricesTemporary";

import "./ListOfProducts.scss";

interface Props {
  big?: boolean;
  product?: ProductProps;
  noWishlistButton?: boolean;
  onRemove?: () => void;
}

export const Product = (props: Props) => {
  const sliderImages = getProductImages(props.product);

  const [selectImg, setSelectImg] = useState(sliderImages[0]?.slug);

  const thumbnail = props.big
    ? props.product?.image?.mediaItemUrl
    : props.product?.image?.sourceUrl;

  // const watermark = props.product?.productWatermarks?.nodes[0]?.name;

  const salePrice = props.product?.regularPrice
    ? parseFloat(props.product?.salePrice)
    : 0;
  const regularPrice = props.product?.regularPrice
    ? parseFloat(props.product?.regularPrice)
    : 0;

  const discountedPercentage =
    salePrice !== 0 ? ((regularPrice - salePrice) / regularPrice) * 100 : 0;

  const variationProductQuantity = getVariableProductStock(
    props.product?.variations?.nodes
  );

  const productStockStatus = () => {
    if (!props.product) {
      return;
    }

    if (props.product?.type === "SIMPLE") {
      return props.product.stockStatus === "OUT_OF_STOCK" ? 0 : 1;
    }

    const variationsStock = props.product.variations?.nodes?.filter(
      (vs) => vs.stockQuantity && vs.stockQuantity >= 1
    );

    return variationsStock?.length;
  };

  const stockStatus = productStockStatus();

  // const getProductColors = () => {
  //   if (props.product?.type === "VARIABLE") {
  //     const colors: any = new Set([]);

  //     props.product?.variations?.nodes?.map((item) => {
  //       item.attributes.nodes.map(
  //         (item: any) => item.name === "pa_ngjyra" && colors.add(item.value)
  //       );
  //     });

  //     return [...colors];
  //   }

  //   const attributes = props.product?.localAttributes?.nodes?.filter(
  //     (item) => item.name === "pa_ngjyra"
  //   );

  //   return attributes ? attributes[0].options : [];
  // };

  // const variationSlug = getProductVariationSlug(
  //   props.product?.variations?.nodes,
  //   selectColor
  // );

  const img = sliderImages?.find((item: any) => item?.slug === selectImg);

  return (
    <div className={`Product${props.big ? " Product--big" : ""}`}>
      {props.onRemove && <RemoveItemButton onClick={props.onRemove} />}
      <div className="Product__image">
        <span className="Product__image__holder">
          <ThemeLink to={`/single-product/${props.product?.slug}`}>
            <Image
              src={img?.sourceUrl || img?.mediaItemUrl || thumbnail}
              alt={props.product?.name}
            />
          </ThemeLink>
        </span>
        {/* <ProductWatermark tag={watermark} /> */}
        <div className="Product__info">
          {discountedPercentage > 0 && (
            <span className="Product__discount">-20%</span>
          )}
          {stockStatus === 0 ? (
            <span className="Product__out-of-stock">Jashtë stokut</span>
          ) : (
            variationProductQuantity <= 1 && (
              <span className="Product__out-of-stock Product__info-limited">
                Sasia e limituar
              </span>
            )
          )}
        </div>
        {!props.noWishlistButton && props?.product?.databaseId && (
          <WishListButton
            id={props.product?.databaseId}
            name={props.product?.name}
          />
        )}
      </div>
      <div className="Product__images-slider">
        {sliderImages?.map((img, index) => {
          return (
            <div
              key={`${img?.slug}-${index}`}
              onMouseOver={() => setSelectImg(img.slug)}
              className={cs(
                "Product__images-slider-img",
                selectImg === img.slug && "active"
              )}
            >
              <img src={img.sourceUrl} alt={props.product?.name} />
            </div>
          );
        })}
      </div>
      <div className="Product__content">
        <ThemeLink to={`/single-product/${props.product?.slug}`}>
          <h4 className="Product__content__title">{props.product?.name}</h4>
        </ThemeLink>
        {props.product?.salePrice ? (
          <ProductPricesTemporary
            regular={props.product?.regularPrice ?? ""}
            sale={props.product?.salePrice ?? ""}
          />
        ) : (
          <ProductPrices
            regular={props.product?.regularPrice ?? ""}
            sale={props.product?.salePrice ?? ""}
          />
        )}
      </div>
    </div>
  );
};
