import { useFormik, FormikValues, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formValidation } from "../translation/strings";
import { JobsFields } from "../../pages/Jobs/Jobs";
import { useAuthContext } from "../context/AuthContext/AuthContext";

interface UseRegisterFormOptions {
  initialValues?: JobsFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<JobsFields>
  ) => Promise<any>;
}

export const useJobsFormik = (options: UseRegisterFormOptions) => {
  const { t } = useTranslation();
  const authCtx = useAuthContext();

  const JobsSchema = Yup.object().shape({
    name: Yup.string().required(t(formValidation.firstName)),
    lastName: Yup.string().required(t(formValidation.lastName)),
    email: Yup.string()
      .email(t(formValidation.validEmail))
      .required(t(formValidation.email)),
    phone: Yup.string().required(t(formValidation.phone)),
    job: Yup.string().required(t(formValidation.selectVariation)),
    file: Yup.string().required("Ju lutem vendoseni CV-n!"),
  });

  return useFormik({
    initialValues: {
      name: authCtx.user?.firstName || "",
      lastName: authCtx.user?.lastName || "",
      email: authCtx.user?.email || "",
      phone: "",
      job: "",
      file: 0,
      message: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: JobsSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type JobsFormik = ReturnType<typeof useJobsFormik>;
