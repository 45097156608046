import {
  ProductSingleProps,
  ProductSingleVariationProps
} from "../../queries/archive";
import { errors, formValidation } from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";
import { canAddToCart } from "./helpers/canAddToCart";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { useCartContext } from "../../lib/context/CartContext/CartContext";

import { getProductImages } from "./helpers/getProductImages";
import { getStockQuantity } from "./helpers/getStockQuantity";
import { getProductDetails } from "./helpers/getProductDetails";
import { hasProductDetails } from "./helpers/hasProductDetails";
import { getProductVariations } from "./helpers/getProductVariations";
import { getProductAttributes } from "./helpers/getProductAttributes";
import { getProductVariationSlug } from "./helpers/getProductVariationSlug";
import { getPossibleVariationsList } from "./helpers/getPossibleVariationsList";
import { getVariationAttributeValue } from "./helpers/getVariationAttributeValue";
import { getFirstVariationWithQuantity } from "./helpers/getFirstVariationWithQuantity";

export const useProductSingleHelpers = () => {
  const notification = useNotification();
  const cartCtx = useCartContext();
  const { t } = useTranslation();

  const getCartItem = (productID: number, variationId: string | undefined) => {
    const items =
      cartCtx?.items?.filter(
        (item: any) => item.product.node?.databaseId === productID
      ) || [];

    if (items.length === 0) {
      return;
    }

    if (variationId) {
      return items.find((item: any) => item.variation.node?.id === variationId);
    }

    return items[0] as any;
  };

  const validateAddToCartProduct = (
    product: ProductSingleProps,
    filteredVariations: ProductSingleVariationProps[],
    quantity: number
  ) => {
    if (cartCtx?.cartUiState?.productAdded) {
      return;
    }

    if (product.type === "SIMPLE" && !product.stockQuantity) {
      notification.warning(t(errors.productNotInStock)); //This product is out of stock.
      return;
    }

    if (product.type === "VARIABLE") {
      if (filteredVariations.length !== 1) {
        notification.warning(t(formValidation.selectVariation)); //Please select a variation to add it to cart.
        return;
      }

      if (!filteredVariations[0].node.stockQuantity) {
        notification.warning(t(errors.variationOutOfStock)); //This variation of the product is out of stock.
        return;
      }
    }

    const variation = filteredVariations[0];
    const cartItem = getCartItem(product.databaseId, variation?.node.id);

    const productStockQuantity =
      cartItem?.product?.node?.type === "SIMPLE"
        ? cartItem?.product?.node?.stockQuantity
        : cartItem?.variation?.node?.stockQuantity;

    if (cartItem && !canAddToCart(cartItem, quantity, variation?.node.id)) {
      notification.warning(
        t(errors.cannotAddAmountOfStockToCart, {
          stockQuantity: productStockQuantity || "?",
          quantity: cartItem?.quantity
        })
      );
      return;
    }

    return true;
  };

  return {
    //validations
    canAddToCart,
    validateAddToCartProduct,
    hasProductDetails,

    //  getters
    getCartItem,
    getStockQuantity,
    getProductImages,
    getProductDetails,
    getProductAttributes,
    getProductVariations,
    getProductVariationSlug,
    getPossibleVariationsList,
    getVariationAttributeValue,
    getFirstVariationWithQuantity
  };
};
