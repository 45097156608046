import React from "react";
import cs from "classnames";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { Sidebar } from "../../Sidebar/Sidebar";
import { ShopAttributes } from "../ui/ShopAttributes";
import { useShopHandlers } from "../../../lib/hooks/useShopHandlers";
import { ShopSelect } from "../ui/ShopSelect/ShopSelect";
import { useShopContext } from "../../../lib/context/ShopContext/ShopContext";
// import { useQuery } from "react-apollo";
// import { GET_BRANDS_LIST } from "../../../queries/brandsQueries";
// import { ShopFilterActions } from "../ui/ShopFilterActions";
// import { Button } from "../../shared/Button/Button";
import { useShopFilterData } from "../../../lib/hooks/useShopFilterData";
import { ShopCategories } from "../ui/ShopCategories";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { ShopSidebarPlaceholder } from "../ui/ShopSidebarPlaceholder";
import { BulkLoader } from "../../shared/BulkLoader/BulkLoader";
import PriceSlider from "../../PriceRange/PriceRange";

import "./ShopSidebar.scss";

interface Props {
  className?: string;
  priceState: { min: number; max: number };
  handleChange: (value: number[]) => void;
  handleAfterChange: (value: number[]) => void;
}

export const ShopSidebar = (props: Props) => {
  const shopHandlers = useShopHandlers();
  const shopCtx = useShopContext();
  const shopFilterData = useShopFilterData();

  const handleOnCategoryChange = (categorySlug: string) => {
    const pathname = categorySlug
      ? `/${shopFilterData?.redirectLinkPrefix}/${categorySlug}`
      : `/${shopFilterData?.redirectLinkPrefix}`;

    shopFilterData?.handleCategoryChange(categorySlug || "");
    shopHandlers.updatePathname(pathname, true);
  };

  const sizeAttributes = shopFilterData?.attributesData?.filter((item) => item.name === "madhesia");

  return (
    <Sidebar>
      <div className={cs("SidebarWrapper", props.className)}>
        {/* <Button
          className={cs("ShopCategories__title mb-5")}
          style={{ border: "none", width: "100%" }}
          onClick={() => router.history.push("/blej-online")}
        >
          Largo filtrat
        </Button> */}

        {/* <div className={cs("ShopCategories__title")} style={{ border: "none" }}>
          Të radhitura sipas
        </div> */}
        {/* <ShopFilterActions className="ShopByCategory__filter_actions mb-2" /> */}

        <ApolloErrorGuard error={shopFilterData.error}>
          <HandleLoadingState
            loading={shopFilterData.loading}
            loadingPlaceholder={<BulkLoader length={1} component={ShopSidebarPlaceholder} />}
          >
            <ShopCategories
              categories={shopFilterData.categories}
              onCategoryChange={handleOnCategoryChange}
            />

            <ShopSelect
              initialOpen
              loading={shopFilterData?.loading}
              relative
              closeOnSelect={false}
              disableMultiselect
              hideIfNoOptions
              showSelectedOption
              label={"Nënkategoritë e produkteve"}
              className='SidebarWrapper__product_type'
              options={shopFilterData?.subCategoryOptions}
              selected={
                shopFilterData?.selectedSubCategory
                  ? [shopFilterData?.selectedSubCategory]
                  : undefined
              }
              onSelect={(selectOption) => {
                const pathName = selectOption
                  ? `/${shopFilterData?.redirectLinkPrefix}/${shopCtx?.data?.category}/${selectOption?.value}`
                  : `/${shopFilterData?.redirectLinkPrefix}/${shopCtx?.data?.category}`;
                shopFilterData?.handleCategoryChange(selectOption?.value || "");
                shopHandlers.updatePathname(pathName, true);
              }}
            />

            <div className='SidebarWrapper__price'>
              <h4 className='ShopCategories__title'>Filtroni sipas cmimit</h4>
              <PriceSlider
                state={props.priceState}
                handleChange={props.handleChange}
                handleAfterOnChange={props.handleAfterChange}
              />
            </div>

            <ApolloErrorGuard error={shopFilterData?.attributes.error}>
              {sizeAttributes?.map((attr, index) => {
                return (
                  <ShopAttributes
                    key={`${attr.slug}-${index}`}
                    loading={shopFilterData?.attributes.loading}
                    isLastAttribute={shopFilterData?.attributesData.length - 1 === index}
                    attributeTitle={attr.label}
                    attribute={attr.slug}
                    options={attr?.terms.map((t) => ({
                      isColor: attr.slug === "PANGJYRA",
                      name: t.name,
                      value: t.slug,
                      label: t.name,
                      count: t.count,
                    }))}
                    onChange={(attr, selectedValue) => {
                      const pathName = shopFilterData?.selectedSubCategory
                        ? `/${shopFilterData?.redirectLinkPrefix}-${selectedValue?.value}/${shopCtx?.data?.category}/${shopFilterData?.selectedSubCategory?.value}`
                        : `/${shopFilterData?.redirectLinkPrefix}-${selectedValue?.value}/${shopCtx?.data?.category}`;
                      shopHandlers.updatePathname(pathName);
                    }}
                  />
                );
              })}
            </ApolloErrorGuard>
          </HandleLoadingState>
        </ApolloErrorGuard>

        {/* <span className="ShopSidebar-brands">
          <HandleLoadingState
            loading={brands.loading}
            loadingPlaceholder={
              <BulkLoader length={5} component={ShopSidebarPlaceholder} />
            }
          >
            <div className={cs("ShopCategories__title")}>Brendet</div>
            <ApolloErrorGuard error={brands.error}>
              {brandsList?.map((brand: any) => (
                <li key={brand.id} className="Menu-dropdown__list-item">
                  <span
                    onClick={() => {
                      if (shopCtx.data.shopType === "category") {
                        router.history.push(
                          `/brands/${brand.slug}/${shopCtx?.data?.category}`
                        );
                        return;
                      }

                      router.history.push(
                        `/brands/${brand.slug}/${shopCtx?.data?.category || ""}`
                      );
                    }}
                    className="ShopCategories__category"
                    data-active={shopCtx?.data?.slug === brand.slug}
                  >
                    {brand?.name}
                  </span>
                </li>
              ))}
            </ApolloErrorGuard>
          </HandleLoadingState>
        </span> */}
      </div>
    </Sidebar>
  );
};
