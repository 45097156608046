import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { getLocalValues, setLocalValue } from "../../../lib/helpers/localStorageHelpers";
import { Modal, ModalHeader, ModalBody, Tooltip } from "reactstrap";

import { CountdownTimer } from "../CountdownTimer/CountdownTimer";
import { GET_COUPON, GetCouponType } from "../../../queries/coupon";
import { BlockButton } from "../BlockButton/BlockButton";

import "./WelcomeModal.scss";

const WelcomeModal = () => {
  const { data } = useQuery<GetCouponType>(GET_COUPON);
  const [modal, setModal] = useState(false);
  const [tooltip, setTooltip] = useState("Kliko ta kopjosh");
  const [tooltipOpened, setTooltipOpened] = useState(false);

  const isActive = !!data?.couponDiscountIsActive;
  const percent = data?.generateCoupon?.percent;
  const minutes = data?.generateCoupon?.minutes;
  const coupon = data?.generateCoupon?.coupon;

  useEffect(() => {
    const localValues = getLocalValues();

    if (localValues.welcomeModal !== "YES" && isActive) {
      setModal(true);
      setLocalValue("welcomeModal", "YES");
    }
  }, [isActive]);

  const toggle = () => {
    setLocalValue("welcomeModal", "YES");
    setModal(false);
  };

  const handleCopy = async () => {
    try {
      document.execCommand("copy");
      await navigator.clipboard.writeText(coupon!);
      setTooltip("E kopjuar!");
      setTimeout(() => setTooltip("Kliko ta kopjosh"), 2000);
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} className='WelcomeModal'>
      <ModalHeader toggle={toggle} />
      <ModalBody className='WelcomeModal__body'>
        <div className='WelcomeModal__body_title'>
          <h2>Përfito zbritje {percent}% duke përdorur kuponin për zbritje brenda minutave</h2>
        </div>
        <div className='WelcomeModal__countDown_timer'>
          {minutes && <CountdownTimer initialMinutes={minutes} />}
        </div>
        <div className='WelcomeModal__countDown_coupon'>{coupon && <h3>{coupon}</h3>}</div>
        <div className='WelcomeModal__body_btn'>
          <Tooltip
            isOpen={tooltipOpened}
            popperClassName='ProductSingleShareButton__tooltip_wrapper'
            innerClassName='ProductSingleShareButton__tooltip'
            placement='top'
            target='shareTooltip'
            trigger='click'
          >
            {tooltip}
          </Tooltip>
          <BlockButton
            id='shareTooltip'
            type='submit'
            className='w-100'
            onClick={handleCopy}
            onMouseOver={() => setTooltipOpened(true)}
            onMouseLeave={() => setTooltipOpened(false)}
          >
            Merre tani
          </BlockButton>
        </div>
        <div onClick={toggle} className='WelcomeModal__body_footer'>
          <span>Jo faleminderit!</span>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WelcomeModal;
