import React from "react";
import cs from "classnames";

import "./ButtonFilterMobile.scss";
import { useSidebarContext } from "../../../lib/context/SidebarContext/SidebarContext";
import { Button } from "../../shared/Button/Button";

interface Props {
  className?: string;
  onClick?: () => void;
}

export const ButtonFilterMobile = (props: Props) => {
  const sidebar = useSidebarContext();

  const handleClick = () => {
    sidebar.openSidebar();
  };

  return (
    <div className={cs("ButtonFilterMobile", props.className)}>
      <Button color="default" onClick={handleClick} rightIcon="filter">
        Filtro
      </Button>
    </div>
  );
};
