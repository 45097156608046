import React from "react";
import cs from "classnames";

import { removeHTMLEntities } from "../../../lib/helpers/removeHTMLEntities";

import "./ProductPricesTemporary.scss";

interface Props {
  sale?: string | null;
  regular?: string;
}

export const ProductPricesTemporary = (props: Props) => {
  const classes = cs("ProductPricesTemporary", {
    sale: props.sale,
  });

  return (
    <div className={classes}>
      {/* <h4 className="regular">
        {props.regular && removeHTMLEntities(props.regular)}
      </h4>
      {props.sale && <h4 className="sale">{removeHTMLEntities(props.sale)}</h4>} */}
      <h4 className="regular">
        {props.sale && removeHTMLEntities(props.sale?.split("-")[1])}
      </h4>
      {props.sale && (
        <h4 className="sale">
          {removeHTMLEntities(props.sale?.split("-")[0])}
        </h4>
      )}
    </div>
  );
};
