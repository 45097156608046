import { RouteProps } from "react-router-dom";

import { Login } from "../pages/Login/Login";
import { Register } from "../pages/Register/Register";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";
import { Profile } from "../pages/Profile/Profile";
import { ShopByCategory } from "../pages/Shop/ShopByCategory";
import { Cart } from "../pages/Cart/Cart";
import { Checkout } from "../pages/Checkout/Checkout";
import { ProductSingle } from "../pages/SingleProduct/SingleProduct";
import { Contact } from "../pages/Contact/Contact";
import { Wishlist } from "../pages/Wishlist/Wishlist";
import { Home } from "../pages/Home/Home";
import { CheckoutConfirmation } from "../pages/Checkout/CheckoutConfirmation";
import { SearchPage } from "../pages/SearchPage/SearchPage";
import { PageDefault } from "../pages/PageDefault/PageDefault";
import { Jobs } from "../pages/Jobs/Jobs";
import { About } from "../pages/About/About";

export enum RouteType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

interface AppRoute extends RouteProps {
  type?: RouteType;
}

export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "login",
    component: Login,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "register",
    component: Register,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "forgot-password",
    component: ForgotPassword,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "reset-password",
    component: ResetPassword,
  },

  // Private Routes
  {
    type: RouteType.PRIVATE,
    path: "my-profile",
    component: Profile,
  },
  {
    type: RouteType.PRIVATE,
    path: "jobs",
    component: Jobs,
  },

  // Normal Routes
  {
    exact: true,
    path: "/",
    component: Home,
  },
  {
    exact: true,
    path: "my-cart",
    component: Cart,
  },
  {
    exact: true,
    path: "checkout",
    component: Checkout,
  },
  {
    path: "checkout/:orderId",
    component: CheckoutConfirmation,
  },
  {
    path: "blej-online",
    component: ShopByCategory,
  },
  {
    path: "veshje-:gjinia/:parentCategory?/:childCategory?",
    component: ShopByCategory,
  },
  {
    path: "veshje-:gjinia-:madhesia/:parentCategory?/:childCategory?",
    component: ShopByCategory,
  },
  {
    path: "zbritje/:parentCategory?/:childCategory?",
    component: ShopByCategory,
  },
  {
    path: "zbritje-:gjinia/:parentCategory?/:childCategory?",
    component: ShopByCategory,
  },
  {
    path: "zbritje-:gjinia-:madhesia/:parentCategory?/:childCategory?",
    component: ShopByCategory,
  },
  {
    path: "koleksioni-ri/:parentCategory?/:childCategory?",
    component: ShopByCategory,
  },
  {
    path: "koleksioni-ri-:gjinia/:parentCategory?/:childCategory?",
    component: ShopByCategory,
  },
  {
    path: "koleksioni-ri-:gjinia-:madhesia/:parentCategory?/:childCategory?",
    component: ShopByCategory,
  },
  {
    path: "single-product/:slug",
    component: ProductSingle,
  },
  {
    path: "rreth-nesh",
    component: About,
  },
  {
    path: "kontakti",
    component: Contact,
  },
  {
    path: "page/:slug",
    component: PageDefault,
  },
  {
    path: "my-list",
    component: Wishlist,
  },
  {
    path: "search",
    component: SearchPage,
  },
];
