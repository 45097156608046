import React, { useContext } from "react";
import { ApolloError } from "apollo-boost";
// import { ProductSingleProps } from "../../../queries/archive";

import { SelectedAttributes } from "./ProductSingleContextProvider";

export interface ProductSingleContextType {
  loading: boolean;
  error: ApolloError | undefined;
  product: any;
  attributes: any;
  variation: any;
  quantity: number;
  inStock: boolean;
  setVariation: (value: any) => void;
  setQuantity: (value: number) => void;
  setInStock: (value: boolean) => void;
  emptySelectedAttributes: () => void;
  selectedAttributes: SelectedAttributes[];
  // product: ProductSingleProps | undefined;
  updateSelectedAttributes: (key: string, value: string) => void;
}

const ProductSingleContextValues: ProductSingleContextType = {
  quantity: 1,
  loading: false,
  inStock: false,
  attributes: [],
  error: undefined,
  product: undefined,
  variation: undefined,
  setInStock: () => {},
  setQuantity: () => {},
  selectedAttributes: [],
  setVariation: () => {},
  emptySelectedAttributes: () => {},
  updateSelectedAttributes: () => {}
};

export const ProductSingleContext = React.createContext<ProductSingleContextType>(
  ProductSingleContextValues
);

export const useProductSingleContext = () => useContext(ProductSingleContext);
