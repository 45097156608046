import React from "react";
import { useQuery } from "react-apollo";
import { getNewInProducts } from "../../../../queries/home";
import { SlideShow } from "../../../SlideShow/SlideShow";
import { ApolloErrorGuard } from "../../../shared/ApolloErrorGuard/ApolloErrorGuard";

export const NewIn = () => {
  const { loading, error, data } = useQuery(getNewInProducts);
  const products = data?.products?.nodes || [];

  return (
    <div className="NewIn animate__animated animate__fadeIn">
      <ApolloErrorGuard error={error}>
        <SlideShow
          loading={loading}
          error={error}
          items={products}
          title="Më të rejat"
        />
      </ApolloErrorGuard>
    </div>
  );
};
