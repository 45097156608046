import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { Col, Row } from "reactstrap";
import { Container } from "../../components/Container/Container";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { LoadingPlaceholder } from "../../components/shared/LoadingPlaceholder/LoadingPlaceholder";
import { useRouter } from "../../lib/hooks/useRouter";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import { GET_PAGE_BY_SLUG } from "../../queries/generalQueries";

export const About = () => {
  const router: any = useRouter();
  const { data, loading, error } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug: "rreth-nesh",
    },
  });

  if (!loading && !data?.pageBy) {
    router.push("/404");
  }

  return (
    <div className='PageDefault'>
      <div className='EventSingle'>
        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={
            <Container>
              <Row>
                <Col xs='12' md='12' className='d-flex justify-content-center mt-5'>
                  <LoadingPlaceholder />
                </Col>
              </Row>
            </Container>
          }
        >
          <ApolloErrorGuard error={error}>
            <DefaultPage
              content={data?.pageBy?.content}
              title={data?.pageBy?.title}
              excerpt={data?.pageBy?.excerpt}
              featuredImage={data?.pageBy?.featuredImage?.node?.sourceUrl}
            />
          </ApolloErrorGuard>
        </HandleLoadingState>
      </div>
    </div>
  );
};
