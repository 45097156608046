import gql from "graphql-tag";

export interface CampaignTypeAPost {
  id: string;
  slug: string;
  title: string;
  featuredImage: {
    node: {
      id: string;
      sourceUrl?: string;
      mediaItemUrl?: string;
    };
  };
  campaignsOptions: {
    title: string;
    link: string;
    text: string;
  };
}

export const GET_TYPE_A_CAMPAIGNS = gql`
  query getTypeBCampaigns {
    generalOptions {
      generalOptions {
        homeAds {
          a1BannerSliderCampaigns {
            ... on Campaign {
              id
              title
              slug
              featuredImage {
                node {
                  id
                  mediaItemUrl
                  sourceUrl(size: LARGE)
                }
              }
              campaignsOptions {
                link
                text
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TYPE_B_CAMPAIGNS = gql`
  query getTypeBCampaigns {
    generalOptions {
      generalOptions {
        homeAds {
          b1GridCampaigns {
            ... on Campaign {
              id
              title
              slug
              featuredImage {
                node {
                  id
                  mediaItemUrl
                  sourceUrl(size: LARGE)
                }
              }
              campaignsOptions {
                title
                link
                text
              }
            }
          }
          b2GridCampaigns {
            ... on Campaign {
              id
              title
              slug
              featuredImage {
                node {
                  id
                  mediaItemUrl
                  sourceUrl(size: LARGE)
                }
              }
              campaignsOptions {
                title
                link
                text
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TYPE_C_CAMPAIGNS = gql`
  query getTypeCCampaigns {
    generalOptions {
      generalOptions {
        homeAds {
          c1FullCampaign {
            ... on Campaign {
              id
              title
              slug
              campaignsOptions {
                title
                link
                text
                video {
                  id
                  mediaItemUrl
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;
