import { useEffect } from "react";

import { useQuery } from "react-apollo";

import { CountrysMethodsResults, NewShippingMethod } from "../../../queries/checkoutQueries";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { GET_AVAILABLE_SHIPPING_METHODS } from "../../../queries/generalQueries";
import { CheckoutFormik } from "../../../lib/formik/useCheckoutFormik";

export const useShippingMethod = (formik: CheckoutFormik) => {
  const cartCtx = useCartContext();
  const country = formik.values.billing.country ?? "";
  const shippingMethods = useQuery<CountrysMethodsResults>(GET_AVAILABLE_SHIPPING_METHODS);

  const shippingMethodsData = shippingMethods.data?.shippingZones?.zones ?? [];

  const beforeFinalShippings = shippingMethodsData?.filter((item) => item.country === country);

  const amount = beforeFinalShippings.filter((item) => item.id.includes("free_shipping"));

  const getShippingMethods = () => {
    if (!country) {
      const res = shippingMethodsData?.filter((item) => item.country === "XK");
      return res;
    }

    const res = beforeFinalShippings?.filter((item) => parseInt(cartCtx.total) < item.min_amount);

    if (res.length === 0) {
      return beforeFinalShippings?.filter((item) => item.id.includes("free_shipping"));
    }

    return beforeFinalShippings.filter((item) => item.id.includes("flat_rate"));
  };

  const finalShippingMethods = getShippingMethods();

  const handleShippingChange = (shippingMethod: NewShippingMethod) => {
    if (!shippingMethod) {
      return;
    }

    cartCtx.updateShippingMethod([shippingMethod.id]);
    formik.setFieldValue("shippingMethod", {
      id: shippingMethod.id,
      label: shippingMethod.label,
      cost: shippingMethod.cost,
    });
    formik.setFieldTouched("shippingMethod");
  };

  useEffect(() => {
    handleShippingChange(finalShippingMethods[0] as any);
  }, [country, shippingMethods]); // eslint-disable-line react-hooks/exhaustive-deps

  return { shippingMethods, amount, finalShippingMethods };
};
