import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Row, Col, FormGroup, Input, Form, FormFeedback } from "reactstrap";

import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useForgotPasswordFormik } from "../../lib/formik/useForgotPasswordFormik";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";

import { actions, messages } from "../../lib/translation/strings";
import { EmptyPage } from "../../components/shared/EmptyMessage/EmptyPage";
import { ThemeContainer } from "../../components/shared/ThemeContainer/ThemeContainer";
import { Button } from "../../components/shared/Button/Button";

import "./ForgotPassword.scss";

export interface ForgotPasswordFields {
  email: string;
  emailConfirm: string;
}

export const ForgotPassword = () => {
  const authCtx = useAuthContext();
  const { t } = useTranslation();
  const notification = useNotification();
  const [state, setState] = useState({
    loading: false,
    success: false
  });

  const formik = useForgotPasswordFormik({
    initialValues: {
      email: "",
      emailConfirm: ""
    },
    onSubmit: async (values) => {
      notification.removeAll();
      setState({
        ...state,
        loading: true
      });
      try {
        await authCtx.forgotPassword(values.email);
        setState({
          ...state,
          success: true,
          loading: false
        });
      } catch (e) {
        notification.danger(e.message);
        setState({
          ...state,
          loading: false
        });
      }
    }
  });

  return (
    <div className="ForgotPassword">
      <ThemeContainer>
        {state.success ? (
          <EmptyPage
            redirectLink="login"
            title={t(actions.approve)}
            buttonText={t(actions.login)}
            description={t(messages.resetPasswordMessage)}
          />
        ) : (
          <div className="ForgotPassword__wrapper">
            <h4>Kam harruar fjalëkalimin</h4>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs="12" sm="12">
                  <FormGroup>
                    <Input
                      type="email"
                      id="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      className="form-control-custom"
                      value={formik.values.email}
                      invalid={!!formik.errors.email && formik.touched.email}
                    />
                    <FormFeedback>{formik.errors.email}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12" sm="12">
                  <FormGroup>
                    <Input
                      type="email"
                      id="emailConfirm"
                      placeholder="Konfirmo email-in"
                      onChange={formik.handleChange}
                      className="form-control-custom"
                      value={formik.values.emailConfirm}
                      invalid={
                        !!formik.errors.emailConfirm &&
                        formik.touched.emailConfirm
                      }
                    />
                    <FormFeedback>{formik.errors.emailConfirm}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Button
                      color="primary"
                      type="button"
                      disabled={state.loading}
                      onClick={() => formik.submitForm()}
                      className="w-100-mobile"
                    >
                      {state.loading ? t(actions.saving) : "Dërgo"}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </ThemeContainer>
    </div>
  );
};
