import React from "react";
import { ApolloErrorGuard } from "../../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { useQuery } from "react-apollo";
import { getOnSaleProducts } from "../../../../queries/home";
import { SlideShow } from "../../../SlideShow/SlideShow";

export const OnSale = () => {
  const { loading, error, data } = useQuery(getOnSaleProducts);

  const products = data?.products?.nodes || [];

  if (products.length === 0) {
    return null;
  }

  return (
    <div className="OnSale animate__animated animate__fadeIn">
      <ApolloErrorGuard error={error}>
        <SlideShow
          loading={loading}
          error={error}
          items={products}
          title="Në zbritje"
        />
      </ApolloErrorGuard>
    </div>
  );
};
