import React from "react";
import queryString from "query-string";
import { useRouter } from "../../../lib/hooks/useRouter";
import { ShopSelect, ShopSelectOptions } from "./ShopSelect/ShopSelect";

import "./ShopAttributes.scss";

export interface AttributesOption {
  isColor?: boolean;
  name: string;
  label: string;
  value: string;
  count?: string | number;
}

interface Props {
  loading?: boolean;
  isLastAttribute?: boolean;
  attributeTitle?: string;
  attribute: string;
  options: AttributesOption[];
  onChange: (attr: string, selectedValues?: ShopSelectOptions) => void;
}

export const ShopAttributes = (props: Props) => {
  const router = useRouter();
  const selected = queryString.parse(router.location.search)[
    props.attribute
  ] as string;

  const options = props.options
    ? props.options.map((o) => ({
        value: o.value,
        label: o.isColor ? (
          <>
            <span className="ShopAttributes__color">
              <div>{o.name}</div>
            </span>
          </>
        ) : (
          <span>{o.label}</span>
        )
      }))
    : [];

  const selectedOptions = options?.filter((option) =>
    selected?.split(",").includes(option.value)
  );

  return (
    <div className="ShopAttributes">
      <ShopSelect
        relative
        loading={props.loading}
        label={props.attributeTitle || ""}
        options={options}
        selected={selectedOptions}
        onSelect={(selectOptions) =>
          props.onChange(props.attribute, selectOptions)
        }
      />
    </div>
  );
};
