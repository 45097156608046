import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Icon } from "../shared/Icon/Icon";
// import { useProductSingleHelpers } from "../lib/useProductSingleHelpers";

// import { ReactComponent as MetroTruck } from "../../../assets/icons/metro-truck.svg";
// import { ReactComponent as FeatherInfo } from "../../../assets/icons/feather-info.svg";

import "./SingleProductDetails.scss";

export const SingleProductDetails = () => {
  const [terms, setTerms] = useState(false);

  // const {
  //   product: { sku }
  // } = useProductSingleContext();
  // const { getProductDetails } = useProductSingleHelpers();
  // const details = getProductDetails(productOptions);
  //
  // const getDetailsContent = (item: string[]) => {
  //   return item[1];
  // };

  return (
    <div className="SingleProductDetails">
      <div className="SingleProductDetails__terms">
        <div
          onClick={() => setTerms(!terms)}
          data-opened={terms}
          className="SingleProductDetails__terms--title"
        >
          <h2>Kushtet e kthimit të produktit</h2>
          <Icon icon="arrow-right" />
        </div>
        <CSSTransition
          in={terms}
          timeout={200}
          classNames="animation"
          unmountOnExit
        >
          <div className="SingleProductDetails__terms_content">
            <p>
              - Kthimi/Ndërrimi i produktit vlen vetëm përmes blerjes online dhe
              jo në lokalet e S//SPRINT, apo partnerët tanë (qendrat tregtare).
            </p>
            <p>
              - Produkti mund të kthehet (përveq të brendshmet) brenda 7 ditëve
              duke poseduar kuponin fiskal, etiketën, paketimin origjinal si dhe
              të jetë i pa dëmtuar (i përdorur).
            </p>
            <p>
              - Ndërrimi (përveq të brendshmet) mund të bëhet poashtu brenda 7
              ditëve përmes shërbimit postar me çmim prej 1.50€ duke i përfshirë
              pikat më lartë.
            </p>
          </div>
        </CSSTransition>
      </div>
      {/* <ul className="SingleProductDetails__list">
        {sku && (
          <li>
            <p>
              <span style={{ width: 100 }} className="mr-5 d-inline-block">
                {t(fields.barcode)}:
              </span>
              {sku}
            </p>
          </li>
        )}

        {details.map((item, index) => {
          const content = getDetailsContent(item);

          return (
            <li key={`${item[0]}-product-details`}>
              <p>
                <span>{t(`fields${_.startCase(item[0])}`)}:</span>
                {content}
                {index !== details.length - 1 && ","}
              </p>
            </li>
          );
        })}
      </ul> */}
    </div>
  );
};
