import React from "react";
import ContentLoader from "react-content-loader";

const SlideShowLoader = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={420}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="300" height="210" />
    <rect x="320" y="0" rx="3" ry="3" width="300" height="210" />
    <rect x="640" y="0" rx="3" ry="3" width="300" height="210" />
    <rect x="960" y="0" rx="3" ry="3" width="300" height="210" />
    <rect x="1280" y="0" rx="3" ry="3" width="300" height="210" />
    <rect x="1600" y="0" rx="3" ry="3" width="300" height="210" />
    <rect x="1920" y="0" rx="3" ry="3" width="300" height="210" />
  </ContentLoader>
);

export default SlideShowLoader;
