import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useState } from "react";
import {
  Row,
  Form,
  Col,
  FormGroup,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";
import { Container } from "../../components/Container/Container";
import { Button } from "../../components/shared/Button/Button";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { useJobsFormik } from "../../lib/formik/useJobsFormik";
import { GET_JOBS_OPTIONS } from "../../queries/generalQueries";
import { JOBS } from "../../queries/mutations/generalMutation";

import "./Jobs.scss";

export interface JobsFields {
  name: string;
  lastName: string;
  email: string;
  phone: string;
  file: number;
  job: string;
  message: string;
}

export const Jobs = () => {
  const ntfCtx = useNotification();
  const { data } = useQuery(GET_JOBS_OPTIONS);

  const [file, setFile] = useState<any>();
  const hiddenFileInput = React.useRef(null);
  const [mutation, { loading }] = useMutation(JOBS);

  const formik = useJobsFormik({
    onSubmit: async (values) => {
      try {
        const res = await mutation({
          variables: {
            email: values.email,
            name: values.name,
            lastName: values.lastName,
            file: values.file,
            phone: values.phone,
            job: values.job,
            message: values.message,
          },
        });

        if (!res) {
          return;
        }
        ntfCtx.success(
          "Faleminderit qe aplikuat se shpejti do t'iu kontakojme!",
          true
        );
        formik.resetForm();
        setFile(undefined);
      } catch (error) {
        ntfCtx.danger("Diqka nuk shkoi mire ju lutemi provoni perseri!", true);
      }
    },
  });

  const storeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }

    const file = (event.target.files && event.target.files[0]) as File;

    const formData = new FormData();
    const token = JSON.parse(localStorage.getItem("SPRINT_USER") as string)
      .authToken;

    try {
      formData.append("file", file);
      formData.append("title", file.name);
      const res = await fetch(
        "https://management.s-sprint.net/wp-json/wp/v2/media",
        {
          method: "POST",
          credentials: "omit",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        }
      );
      const data = await res.json();
      setFile(data);
      formik.setFieldValue("file", data.id);
    } catch (e) {}
  };

  const jobsOptions =
    data?.generalOptions?.generalOptions?.applyForm?.jobOptions ?? [];

  return (
    <div className="Jobs">
      <Container>
        <h4>Pune</h4>
        <div className="Jobs__wrapper">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs="12" sm="6">
                <FormGroup className="form-group">
                  <Input
                    type="text"
                    id="name"
                    placeholder="Emri"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    invalid={!!formik.errors.name && formik.touched.name}
                  />
                  <FormFeedback>{formik.errors.name}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup>
                  <Input
                    type="text"
                    id="lastName"
                    placeholder="Mbiemri"
                    onChange={formik.handleChange}
                    className="form-control-custom"
                    value={formik.values.lastName}
                    invalid={
                      !!formik.errors.lastName && formik.touched.lastName
                    }
                  />
                  <FormFeedback>{formik.errors.lastName}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup>
                  <Input
                    type="email"
                    id="email"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    className="form-control-custom"
                    value={formik.values.email}
                    invalid={!!formik.errors.email && formik.touched.email}
                  />
                  <FormFeedback>{formik.errors.email}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup>
                  <Input
                    type="text"
                    id="phone"
                    placeholder="Numri i telefonit"
                    onChange={formik.handleChange}
                    className="form-control-custom"
                    value={formik.values.phone}
                    invalid={!!formik.errors.phone && formik.touched.phone}
                  />
                  <FormFeedback>{formik.errors.phone}</FormFeedback>
                </FormGroup>
              </Col>

              <Col xs="12" sm="6">
                <FormGroup>
                  <Input
                    disabled={loading}
                    type="select"
                    placeholder="Lloji i punes"
                    id="job"
                    invalid={!!formik.errors.job && formik.touched.job}
                    onChange={formik.handleChange}
                    defaultValue=""
                    value={formik.values.job}
                  >
                    <option value="">
                      {loading ? "Ju lutem prisni..." : "Zgjidh llojin e punes"}
                    </option>
                    {jobsOptions?.map((item: { title: string }) => (
                      <option
                        key={`${item.title}-register-job`}
                        value={item.title}
                      >
                        {item.title}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>{formik.errors.job}</FormFeedback>
                </FormGroup>
              </Col>

              <Col xs="12" sm="6">
                <FormGroup>
                  <Label className="form-control Jobs--file" for="cv">
                    {file ? file.title?.raw : "Choose file..."}
                  </Label>
                  <Input
                    ref={hiddenFileInput}
                    type="file"
                    id="cv"
                    placeholder="CV"
                    onChange={storeFile}
                    style={{ display: "none" }}
                    className="form-control"
                    value={undefined}
                    invalid={!!formik.errors.file && formik.touched.file}
                    accept=".pdf"
                  />
                  <FormFeedback>{formik.errors.file}</FormFeedback>
                </FormGroup>
              </Col>

              <Col xs="12" sm="12">
                <FormGroup>
                  <Input
                    type="textarea"
                    id="message"
                    placeholder="Mesazhi"
                    onChange={formik.handleChange}
                    className="form-control-custom"
                    value={formik.values.message}
                    invalid={!!formik.errors.message && formik.touched.message}
                  />
                  <FormFeedback>{formik.errors.message}</FormFeedback>
                </FormGroup>
              </Col>

              <div className="w-100" />
              <Col xs="12">
                <FormGroup>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={loading}
                    className="w-100-mobile"
                  >
                    {loading ? <Spinner size="sm" /> : "Apliko"}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </div>
  );
};
