import React, { useCallback, useRef, useState } from "react";
import Flickity from "react-flickity-component";
import { ApolloError } from "apollo-boost";
import { Container } from "../Container/Container";
import { Product } from "../ListOfProducts/Product";
import { ProductProps } from "../../types/productTypes";
import SlideShowLoader from "./SlideShowLoader";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";
import { Icon } from "../shared/Icon/Icon";

import "flickity/dist/flickity.css";
import "./SlideShow.scss";

interface SlideShowProps {
  items: any;
  title: string;
  loading: boolean;
  error: ApolloError | undefined;
  paddingLeft?: number;
}

const CustomArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "rgba(238, 238, 238, 0.8)",
        borderRadius: 3,
        "&:hover": {
          borderColor: "#ff681c",
        },
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-right" style={{ color: "#ef3636" }} />
    </span>
  );
};

const CustomArrowLeft = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "rgba(238, 238, 238, 0.8)",
        borderRadius: 3,
        marginRight: 10,
        "&:hover": {
          borderColor: "#ff681c",
        },
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" style={{ color: "#ef3636" }} />
    </span>
  );
};

export const SlideShow = (props: SlideShowProps) => {
  const flickity = useRef<Flickity | null>(null);
  const [titleRef, setTitleRef] = useState<any>(null);
  const windowWidth = useWindowWidth();

  const isTablet = windowWidth < 1024;

  const getTitleRef = useCallback((c) => {
    if (c !== null) {
      setTitleRef(c);
    }
  }, []);

  if (props.loading) {
    return (
      <div className="SlideShow">
        <div className="SlideShow__wrapper">
          <SlideShowLoader />
        </div>
      </div>
    );
  }

  if (props.error) {
    return null;
  }

  return (
    <div className="SlideShow">
      <Container className="p-0-mobile d-flex justify-content-between">
        <h2 ref={getTitleRef} className="font-weight-bold">
          {props.title}
        </h2>
        <div className="d-flex">
          <div onClick={() => flickity.current?.previous()}>
            <CustomArrowLeft />
          </div>
          <div onClick={() => flickity.current?.next()}>
            <CustomArrow />
          </div>
        </div>
      </Container>
      <div className="SlideShow__wrapper">
        <Flickity
          flickityRef={(c) => (flickity.current = c)}
          className={"carousel"}
          elementType={"div"}
          options={{
            wrapAround: false,
            draggable: true,
            cellAlign: props.items?.length <= 4 ? "center" : "left",
            freeScroll: true,
            resize: true,
            pageDots: false,
            prevNextButtons: false,
            contain: true,
            bgLazyLoad: true,
            lazyLoad: 1,
          }}
          disableImagesLoaded={false}
        >
          {!isTablet && (
            <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
          )}
          {props.items?.map((product: ProductProps, index: number) => {
            return (
              <div
                key={`${product.id}-${index}`}
                className="SlideShow-box"
                id={`SlideShow-${product.slug}`}
              >
                <Product product={product} key={`${product.id}-${index}`} />
              </div>
            );
          })}
          {!isTablet && (
            <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
          )}
        </Flickity>
      </div>
    </div>
  );
};
